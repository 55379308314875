import React from 'react';

export const DeliveryMethod = ({ formData, setFormData }) => {
  const handleMethodChange = (e) => {
    setFormData({ ...formData, deliveryMethod: e.target.value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="delivery-method">
      <h3>Delivery Method</h3>
      <select name="deliveryMethod" onChange={handleMethodChange} required>
        <option value="">Select Delivery Method</option>
        <option value="courier">The Courier Guy</option>
        <option value="postnet2door">PostNet2Door</option>
        <option value="paxi">PAXI</option>
        <option value="postnet2postnet">PostNet2PostNet</option>
      </select>

      {formData.deliveryMethod === 'courier' || formData.deliveryMethod === 'postnet2door' ? (
        <>
          <label>
            Building / Floor / Unit
            <input type="text" name="building" value={formData.building} onChange={handleInputChange} />
          </label>
          <label>
            Street Address *
            <input type="text" name="streetAddress" value={formData.streetAddress} onChange={handleInputChange} required />
          </label>
          <label>
            Suburb *
            <input type="text" name="suburb" value={formData.suburb} onChange={handleInputChange} required />
          </label>
          <label>
            City *
            <input type="text" name="city" value={formData.city} onChange={handleInputChange} required />
          </label>
          <label>
            Province *
            <select name="province" value={formData.province} onChange={handleInputChange} required>
              <option value="">Select Province</option>
              <option value="Eastern Cape">Eastern Cape</option>
              <option value="Free State">Free State</option>
              <option value="Gauteng">Gauteng</option>
              <option value="KwaZulu-Natal">KwaZulu-Natal</option>
              <option value="Limpopo">Limpopo</option>
              <option value="Mpumalanga">Mpumalanga</option>
              <option value="Northern Cape">Northern Cape</option>
              <option value="North West">North West</option>
              <option value="Western Cape">Western Cape</option>
            </select>
          </label>
          <label>
            Postal Code
            <input type="text" name="postalCode" value={formData.postalCode} onChange={handleInputChange} />
          </label>
          <label>
            Collection Instructions
            <textarea name="collectionInstructions" value={formData.collectionInstructions} onChange={handleInputChange} />
          </label>
          <p>Delivery Cost: R90</p>
        </>
      ) : formData.deliveryMethod === 'paxi' ? (
        <>
          <label>
            Please Enter PAXI Point
            <input type="text" name="paxiPoint" value={formData.paxiPoint} onChange={handleInputChange} required placeholder="e.g. (P0101) PEP Johannesburg" />
          </label>
          <label>
            PAXI Point Address
            <input type="text" name="paxiAddress" value={formData.paxiAddress} onChange={handleInputChange} required />
          </label>
          <p>Delivery Cost: R56</p>
        </>
      ) : formData.deliveryMethod === 'postnet2postnet' ? (
        <>
          <label>
            Please Enter PostNet Address
            <input type="text" name="postnetAddress" value={formData.postnetAddress} onChange={handleInputChange} required />
          </label>
          <p>Delivery Cost: R99</p>
        </>
      ) : null}
    </div>
  );
};
