import React, { useEffect } from "react";
import { useParams, Link, Navigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ProductCard } from './ProductCard';
import { ShopReviews } from '../Shop/ShopReviews';
import { useCart } from '../Cart/CartContext';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { fetchShop, fetchProducts, fetchReviews, fetchReviewComments, addReview, addReviewComment } from '../Common/apiService';
import '../../styles/productlist.css';
import { Loader } from '../Common/Loader';

export const ProductList = () => {
  const { shop_id } = useParams();
  const queryClient = useQueryClient();
  const { cart, addToCart, totalQuantity } = useCart();

  const { data: shopData, error: shopError, isLoading: shopLoading } = useQuery({
    queryKey: ['shop', shop_id],
    queryFn: () => fetchShop(shop_id),
  });

  const { data: productsData, error: productsError, isLoading: productsLoading } = useQuery({
    queryKey: ['products', shop_id],
    queryFn: () => fetchProducts(shop_id),
  });

  const { data: reviewsData, error: reviewsError, isLoading: reviewsLoading } = useQuery({
    queryKey: ['reviews', shop_id],
    queryFn: () => fetchReviews(shop_id),
    onSuccess: async (reviews) => {
      for (const review of reviews) {
        const comments = await fetchReviewComments(shop_id, review.review_id);
        review.comments = comments;
      }
    },
  });

  const addReviewMutation = useMutation({
    mutationFn: (newReviewData) => addReview(shop_id, newReviewData),
    onSuccess: () => {
      queryClient.invalidateQueries(['reviews', shop_id]);
      toast.success('Review added successfully!');
    },
  });

  const addReviewCommentMutation = useMutation({
    mutationFn: ({ reviewId, commentText }) => addReviewComment(shop_id, reviewId, commentText),
    onSuccess: () => {
      queryClient.invalidateQueries(['reviews', shop_id]);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (shopLoading || productsLoading || reviewsLoading) {
    return <Loader message={`Welcome to ${shopData ? shopData.shop_name : 'our shop'}`} />;
  }
  
  if (shopError || productsError || reviewsError) return <p>Error fetching data</p>;

  // Check if shop is inactive
  if (shopData.shop_status === "inactive") {
    return <Navigate to="/inactive" />;
  }
  

  return (
    <div
      className="product-list-section"
      style={{
        backgroundImage: `url(${shopData.shop_background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    >
      <Helmet>
        <title>Creatives SA - {shopData.shop_name}</title>
      </Helmet>
      <nav className="shop-navbar">
        <a href="#home" className="logo">{shopData.shop_name}</a>
        <ul className="shop-menu">
          <li><a href="#shop-footer">Contact</a></li>
          <li>
            <Link to="/cart" className="cart-icon">
              <i className="ri-shopping-cart-fill"></i>
              <span className="badge">{totalQuantity}</span>
            </Link>
          </li>
        </ul>
      </nav>
      <div className="shop-header" id="home">
        <div className="shop-header-content">
          <h1>{shopData.shop_name}</h1>
          <p>{shopData.shop_description}</p>
        </div>
        <div className="shop-header-image">
          <img src={shopData.shop_image} alt={` ${shopData.shop_name}`} />
        </div>
      </div>
      <h2 className="products-title">Products from {shopData.shop_name}</h2>
      <div className="product-list">
      {Array.isArray(productsData) && productsData.map((product) => (
  <ProductCard
    key={product.product_id}
    product={product}
    addToCart={addToCart}
    cart={cart}
  />
))}

      </div>
      <div className="reviews" id="reviews">
        <ShopReviews
          onAddReview={(newReviewData) => addReviewMutation.mutate(newReviewData)}
          onAddReviewComment={(reviewId, commentText) => addReviewCommentMutation.mutate({ reviewId, commentText })}
          reviews={reviewsData}
        />
      </div>
      <div className="content1"></div>
      <footer className="shop-footer" id="shop-footer">
        <div className="main-content">
          <div className="left box">
            <h2>About us</h2>
            <div className="content">
              <p>{shopData.shop_about}</p>
            </div>
          </div>
          <div className="center box">
            <h2>CONTACT DETAILS</h2>
            <div className="content">
              <div className="place">
                <span className="fas fa-map-marker-alt"></span>
                <span className="text">{shopData.shop_address}</span>
              </div>
              <div className="phone">
                <span className="fas fa-phone-alt"></span>
                <span className="text">{shopData.owner_phone}</span>
              </div>
              <div className="email">
                <span className="fas fa-envelope"></span>
                <span className="text">{shopData.owner_email}</span>
              </div>
            </div>
          </div>
          <div className="right box">
            <h2>LINKS</h2>
            <div className="content">
              <form action="#">
                <div className="LinkButton1">
                  <p>
                    <Link to="/cart" className="LinkButton">
                      <i className="ri-shopping-cart-fill"></i> Cart
                    </Link>
                  </p>
                </div>
                <div className="LinkButton2">
                  <p>
                    <Link to="/track-orders" className="LinkButton">
                      <i className="ri-truck-line"></i> Track Your Order(s)
                    </Link>
                  </p>
                </div>
                <div className="LinkButton3">
                  <p>
                    <Link to="/" className="LinkButton">
                      <i className="ri-home-3-line"></i> Home
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="bottom-text">
          <center>Thank You For Shopping With {shopData.shop_name}</center>
        </div>
      </footer>
    </div>
  );
};
