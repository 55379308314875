import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { 
  fetchShops, 
  fetchProducts, 
  fetchCreativesHubUsers, 
  fetchCreativesHubProducts,
  fetchApplications,           // Import the functions for fetching applications
  fetchCreativesHubApplications 
} from "../Common/apiServiceAdmin"; 
import "../../styles/admin-dashboard.css";

export const AdminDashboard = () => {
  const [totalShops, setTotalShops] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalCreativesHubUsers, setTotalCreativesHubUsers] = useState(0);
  const [totalCreativesHubProducts, setTotalCreativesHubProducts] = useState(0);
  const [shopApplicationsCount, setShopApplicationsCount] = useState(0); // State for shop applications count
  const [creativesHubApplicationsCount, setCreativesHubApplicationsCount] = useState(0); // State for creatives hub applications count
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    try {
      const shopsData = await fetchShops();
      setTotalShops(shopsData.length);

      const productsData = await fetchProducts();
      setTotalProducts(productsData.length);

      const creativesHubUsersData = await fetchCreativesHubUsers();
      setTotalCreativesHubUsers(creativesHubUsersData.length);

      const creativesHubProductsData = await fetchCreativesHubProducts();
      setTotalCreativesHubProducts(creativesHubProductsData.length);
      
      const shopApplicationsData = await fetchApplications(); // Fetch shop applications
      setShopApplicationsCount(shopApplicationsData.length); // Set the shop applications count

      const creativesHubApplicationsData = await fetchCreativesHubApplications(); // Fetch creatives hub applications
      setCreativesHubApplicationsCount(creativesHubApplicationsData.length); // Set the creatives hub applications count

    } catch (error) {
      console.error("Error fetching statistics", error);
      setError("Error fetching statistics. Please try again later.");
    }
  };

  return (
    <div className="admin-dashboard">
      <div className="dashboard-header">
        <h2>Creatives SA Dashboard</h2>
      </div>

      <div className="dashboard-stats">
        <div className="dashboard-stat">
          <h3>Total Shops</h3>
          <p>{totalShops}</p>
        </div>
        <div className="dashboard-stat">
          <h3>Total Shop Products</h3>
          <p>{totalProducts}</p>
        </div>
        <div className="dashboard-stat">
          <h3>Total Creatives Hub Users</h3>
          <p>{totalCreativesHubUsers}</p>
        </div>
        <div className="dashboard-stat">
          <h3>Total Creatives Hub Products</h3>
          <p>{totalCreativesHubProducts}</p>
        </div>
      </div>

      <div className="dashboard-buttons">
        <Link to="/admin/shops" className="dashboard-button">
          Dashboard
        </Link>
        <Link to="/admin/creatives-hub-users" className="dashboard-button">
          Creatives Hub
        </Link>
        <Link to="/admin/blogs" className="dashboard-button">
          Blog
        </Link>
        <Link to="/admin/reviews" className="dashboard-button">
          Reviews
        </Link>
        <Link to="/admin/packages" className="dashboard-button">
          Subscriptions
        </Link>
        <Link to="/admin/shops" className="dashboard-button">
          Finance
        </Link>
      </div>

      <div className="dashboard-shop-list">
        <h3>Applications</h3>
        <ul>
          <li>
            <Link to="/admin/applications">
              Shop Applications ({shopApplicationsCount})
            </Link>
          </li>
          <li>
            <Link to="/admin/creatives-hub-applications">
              Creatives Hub Applications ({creativesHubApplicationsCount})
            </Link>
          </li>
        </ul>
      </div>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};
