import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Modal from 'react-modal';
import { ToastContainer } from "react-toastify";
import "remixicon/fonts/remixicon.css";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

Modal.setAppElement('#root');

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        theme="light"
        position="top-right"
        autoClose={2000}
        closeOnClick
        pauseOnHover={false}
      />
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
