import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { AdminDashboard } from "./AdminDashboard";
import { ConfirmationModal } from "../Common/ConfirmationModal";
import { fetchCreativesHubApplications, deleteCreativesHubApplication, addNewCreativesHubUser } from "../Common/apiServiceAdmin";
import "../../styles/admin-manage-applications.css";

export const AdminManageCreativesHubApplications = () => {
  const [applications, setApplications] = useState([]);
  const [error, setError] = useState(null);
  const [applicationToDelete, setApplicationToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCreativesHubApplications();
        setApplications(data);
      } catch (err) {
        console.error(err.message);
        setError("Error fetching applications. Please try again later.");
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleDeleteApplication = async (hubApplicationId) => {
    setApplicationToDelete(hubApplicationId);
    setShowConfirmationModal(true);
  };

  const deleteApplicationById = async (hubApplicationId) => {
    try {
      const success = await deleteCreativesHubApplication(hubApplicationId);
      if (success) {
        setApplications((prevApplications) =>
          prevApplications.filter((application) => application.hub_application_id !== hubApplicationId)
        );
        toast.success("Application deleted successfully!");
      } else {
        setError("Failed to delete application. Please try again later.");
      }
    } catch (err) {
      console.error(err.message);
      setError("Failed to delete application. Please try again later.");
    }
  };

  const handleDeleteConfirmation = async () => {
    await deleteApplicationById(applicationToDelete);
    setApplicationToDelete(null);
    setShowConfirmationModal(false);
  };

  const handleCancelDelete = () => {
    setApplicationToDelete(null);
    setShowConfirmationModal(false);
  };

  const handleStatusUpdate = async (hubApplicationId) => {
    try {
      const approvedApplication = applications.find(
        (app) => app.hub_application_id === hubApplicationId
      );
      if (approvedApplication) {
        const newCreativesHubUserData = {
          hub_full_name: approvedApplication.hub_full_name,
          hub_email: approvedApplication.hub_email,
          hub_phone_number: approvedApplication.hub_phone_number,
          hub_bio: approvedApplication.hub_bio,
          hub_address: approvedApplication.hub_address,
          hub_market: approvedApplication.hub_market,
        };

        const response = await addNewCreativesHubUser(newCreativesHubUserData);
        if (response) {
          toast.success("User added to Creatives Hub successfully!");
          await deleteApplicationById(hubApplicationId);
        } else {
          setError("Failed to add user to Creatives Hub. Please try again later.");
        }
      } else {
        setError("Failed to find approved application details.");
      }
    } catch (err) {
      console.error(err.message);
      setError("Failed to add user to Creatives Hub. Please try again later.");
    }
  };

  return (
    <div className="admin-manage-applications">
      <Helmet>
        <title>Creatives SA - Manage Creatives Hub Applications</title>
      </Helmet>
      <AdminDashboard />
      <h1>Manage Creatives Hub Applications</h1>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          {showConfirmationModal && (
            <ConfirmationModal
              message="Are you sure you want to delete this application?"
              onConfirm={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          {applications.length === 0 ? (
            <p>There are currently no new applications.</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Application ID</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>Market</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {applications.map((application) => (
                  <tr key={application.hub_application_id}>
                    <td>{application.hub_application_id}</td>
                    <td>{application.hub_full_name}</td>
                    <td>{application.hub_email}</td>
                    <td>{application.hub_phone_number}</td>
                    <td>{application.hub_address}</td>
                    <td>{application.hub_market}</td>
                    <td>
                      <button
                        className="approve-btn"
                        onClick={() => handleStatusUpdate(application.hub_application_id)}
                      >
                        Approve
                      </button>
                      <button
                        className="delete-btn"
                        onClick={() => handleDeleteApplication(application.hub_application_id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};
