import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";

// HOME PAGE IMPORTS:
import { Navbar } from "./components/Common/Navbar";
import   Home  from "./components/Common/Home";
import { ProductList } from "./components/Product/ProductList";
import { Cart } from "./components/Cart/Cart";

import { CartProvider } from "./components/Cart/CartContext";
import { Footer } from "./components/Common/Footer";
import { ScrollUp } from "./components/Common/ScrollUp";
import { CreativesHubHome } from "./components/CreativesHub/CreativesHubHome";
import { SignUpForm } from "./components/Common/SignUpForm";
import { Login } from "./components/Auth/Login";
import { ProtectedRoutes } from "./components/Auth/ProtectedRoutes";
import  BlockedShopAlert  from "./components/Shop/BlockedShopAlert";
import { ShopApplicationComplete }  from "./components/Common/ShopApplicationComplete";
import { About }  from "./components/Common/AboutUs";
import { FAQ }  from "./components/Common/FAQ";
import { PrivacyPolicy }  from "./components/Common/PrivacyPolicy";
import { TermsOfService }  from "./components/Common/TermsOfService";
import { TrackYourDelivery } from "./components/Common/TrackYourDelivery";
import { Checkout } from "./components/Checkout/Checkout";

import { CreativesHubCreatorProductList } from "./components/CreativesHub/Creator/CreativesHubCreatorProductList";




function App() {
  return (
    <Router>
      <HelmetProvider>
        <CartProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/inactive" element={<BlockedShopAlert />} /> 
            <Route path="/application-complete" element={<ShopApplicationComplete />} /> 
            <Route path="/track-orders" element={<TrackYourDelivery />} /> 
            <Route path="/sign-up" element={<SignUpForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/shop/:shop_id" element={<ProductList />} />
            <Route path="/creatives-hub-creators" element={<CreativesHubCreatorProductList />} />
            <Route
              path="/creatives-hub-home"
              element={<CreativesHubHome />}
            />
            <Route
              path="/about"
              element={<About />}
            />
            <Route
              path="/faq"
              element={<FAQ />}
            />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route
              path="/terms-of-service"
              element={<TermsOfService />}
            />

            {/* ProtectedRoutes */}
            <Route path="/admin/*" element={<ProtectedRoutes />} />
          </Routes>
          <Footer />
          <ScrollUp />
        </CartProvider>
      </HelmetProvider>
    </Router>
  );
}

export default App;
