import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import "../../styles/download-pdf-button.css";

const DownloadCartButton = ({ targetId, fileName }) => {
  const handleDownloadPDF = async () => {
    const input = document.getElementById(targetId);
    if (!input) return;

    const images = input.getElementsByTagName('img');
    const loadPromises = [];
    for (let i = 0; i < images.length; i++) {
      if (!images[i].complete) {
        loadPromises.push(new Promise((resolve) => {
          images[i].onload = resolve;
          images[i].onerror = resolve;
        }));
      }
    }
    await Promise.all(loadPromises);

    // Create a canvas and generate PDF
    const canvas = await html2canvas(input, { useCORS: true });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 295; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save(fileName);
  };

  return (
    <button onClick={handleDownloadPDF} className="download-btn">
      <i className="ri-download-line"></i>
      Download Cart
    </button>
  );
};

export default DownloadCartButton;
