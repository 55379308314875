import React from "react";
import Modal from "react-modal";
import "../../../styles/creatives-hub-auction-modal.css";

export const CreativesHubAuctionModal = ({ isOpen, closeModal, product }) => {
  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Product Information"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000, // Ensure this is high enough
        },
        content: {
          maxWidth: "600px",
          margin: "auto",
          padding: "20px",
          borderRadius: "10px",
          zIndex: 1001, // Ensure this is high enough
        },
      }}
    >
      <div className="modal-view">
        <img src={product.image} alt={product.title} className="modal-image" />
        <h2>{product.title}</h2>
        <p>{product.description}</p>
        <p><strong>By:</strong> {product.auctioneer}</p>
        <button onClick={handleClose} className="close-button">Close</button>
      </div>
    </Modal>
  );
};
