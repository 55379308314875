import React, { useEffect, useState } from "react";
import "../../styles/loader.css";

export const Loader = ({ message }) => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const minimumDuration = 3000;

    setShowLoader(true);

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, minimumDuration);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`loader-container ${showLoader ? 'show' : 'hide'}`}>
      <div className="loader-overlay"></div>
      <div className="loader-content">
        <h1>{message}</h1><br/><br/>
        <span className="loader"></span>
      </div>
    </div>
  );
};
