import React, { useState, useRef, useEffect } from 'react';
import { CreativesHubAuctionModal } from './CreativesHubAuctionModal';
import { CreativesHubAuctionBiddingModal } from './CreativesHubAuctionBiddingModal';
import "../../../styles/creatives-hub-auction.css";

const auctionItems = [
  {
    id: 1,
    title: 'Vintage Design Tuxido',
    description: 'A classic film camera in excellent condition.',
    currentBid: 'R1250',
    minBid: 'R1200',
    auctioneer: 'Fashion Designer, Ronald Ngwenya',
    image: 'https://via.placeholder.com/300',
    endTime: new Date().getTime() + 60000
  },
  {
    id: 2,
    title: 'Antique Vase',
    description: 'A beautiful antique vase from the 19th century customized with modern painting.',
    currentBid: 'R375',
    minBid: 'R325',
    auctioneer: 'Thato Sodi',
    image: 'https://via.placeholder.com/300',
    endTime: new Date().getTime() + 120000
  },
  {
    id: 3,
    title: 'Rare Painting',
    description: 'An exquisite painting by a renowned artist.',
    currentBid: 'R5000',
    minBid: 'R5250',
    auctioneer: 'Renowned artist, Lincoln Mabesa',
    image: 'https://via.placeholder.com/300',
    endTime: new Date().getTime() + 180000
  },
  {
    id: 4,
    title: 'Kiernan Forbes Painting',
    description: 'An exquisite painting of South African iconic musician.',
    currentBid: 'R15500',
    minBid: 'R15000',
    auctioneer: 'Art Collector, Jabu Kekana',
    image: 'https://via.placeholder.com/300',
    endTime: new Date().getTime() + 240000
  }
  // Add more items here
];

const calculateTimeLeft = (endTime) => {
  const difference = endTime - new Date().getTime();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } else {
    timeLeft = {
      minutes: 0,
      seconds: 0,
    };
  }
  return timeLeft;
};

export const CreativesHubAuction = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBiddingModalOpen, setIsBiddingModalOpen] = useState(false);
  const [timeLefts, setTimeLefts] = useState(
    auctionItems.map((item) => calculateTimeLeft(item.endTime))
  );

  const itemsPerPage = 32;
  const auctionListRef = useRef(null);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = auctionItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(auctionItems.length / itemsPerPage);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLefts(auctionItems.map((item) => calculateTimeLeft(item.endTime)));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      auctionListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      auctionListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleOpenBiddingModal = (product) => {
    setSelectedProduct(product);
    setIsBiddingModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const handleCloseBiddingModal = () => {
    setIsBiddingModalOpen(false);
    setSelectedProduct(null);
  };

  return (
    <div className="auction-container" ref={auctionListRef}>
      <h2 className="title">Explore an Exhibition of Distinctive Artistry: Bid & Curate Unique Creations!</h2>
      <div className="auction-grid">
        {currentItems.map((item, index) => {
          const timeLeft = timeLefts[index];
          const isAuctionClosed = timeLeft.minutes === 0 && timeLeft.seconds === 0;

          return (
            <div className={`auction-card ${isAuctionClosed ? 'closed' : ''}`} key={item.id}>
              <img src={item.image} alt={item.title} className="auction-image" />
              <div className="auction-details">
                <h3 className="auction-title">{item.title}</h3>
                <div className="auction-footer">
                  <span className="current-bid">Current Bid: {item.currentBid}</span>
                  <span className="minimum-bid">Minimum Bid Amount: {item.minBid}</span>
                  <span className={`time-left ${isAuctionClosed ? 'closed' : ''}`}>
                    Time Left: {isAuctionClosed ? 'Auction Closed' : `${timeLeft.minutes}:${timeLeft.seconds < 10 ? '0' : ''}${timeLeft.seconds}`}
                  </span>
                  <span className={`auction-status ${isAuctionClosed ? 'closed' : ''}`}>
                    {isAuctionClosed ? 'AUCTION CLOSED' : 'AUCTION OPEN'}
                  </span>
                  <button onClick={() => handleOpenModal(item)} className="info-button">More Info</button>
                  <button 
                    className="bid-button" 
                    onClick={() => handleOpenBiddingModal(item)}
                    disabled={isAuctionClosed}
                  >
                    Make a Bid
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="pagination-container">
        <button
          className="pagination-button"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="pagination-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="pagination-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      {selectedProduct && (
        <>
          <CreativesHubAuctionModal
            isOpen={isModalOpen}
            closeModal={handleCloseModal}
            product={selectedProduct}
          />
          <CreativesHubAuctionBiddingModal
            isOpen={isBiddingModalOpen}
            closeModal={handleCloseBiddingModal}
            product={selectedProduct}
          />
        </>
      )}
    </div>
  );
};