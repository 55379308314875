import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Loader } from '../Common/Loader';
import { AdminDashboard } from "./AdminDashboard";
import { fetchShopSubscriptionPackages, updateSubscriptionPackage } from '../Common/apiServiceAdmin';
import '../../styles/admin-edit-subscriptions-packages.css';

export const AdminEditSubscriptionPackages = () => {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = async () => {
    setIsLoading(true);
    try {
      const data = await fetchShopSubscriptionPackages();
      setPackages(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePackage = async (package_id, updatedPackage) => {
    setIsLoading(true);
    try {
      const updatedPackageData = await updateSubscriptionPackage(package_id, updatedPackage);
      setPackages(prevPackages =>
        prevPackages.map(pkg =>
          pkg.subscription_package_id === package_id ? updatedPackageData : pkg
        )
      );
      toast.success('Package updated successfully!');
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader message="Loading subscription packages..." />;
  }

  return (
    <>
    <AdminDashboard />
    <div className="admin-subscription-packages">
      <h2>EDIT SUBSCRIPTION PACKAGES:</h2>
      {packages.length === 0 ? (
        <p>No subscription packages found.</p>
      ) : (
        packages.map(pkg => (
          <div key={pkg.subscription_package_id}>
            <h3>{pkg.package_name}</h3>
            <form
              onSubmit={e => {
                e.preventDefault();
                handleUpdatePackage(pkg.subscription_package_id, pkg);
              }}
            >
              <label>
                Package Name:
                <input
                  type="text"
                  name="package_name"
                  value={pkg.package_name}
                  onChange={e =>
                    setPackages(prevPackages =>
                      prevPackages.map(p =>
                        p.subscription_package_id === pkg.subscription_package_id
                          ? { ...p, package_name: e.target.value }
                          : p
                      )
                    )
                  }
                  required
                />
              </label>
              <br />
              <label>
                Package Description:
                <textarea
                  name="package_description"
                  value={pkg.package_description}
                  onChange={e =>
                    setPackages(prevPackages =>
                      prevPackages.map(p =>
                        p.subscription_package_id === pkg.subscription_package_id
                          ? { ...p, package_description: e.target.value }
                          : p
                      )
                    )
                  }
                ></textarea>
              </label>
              <br />
              <label>
                Monthly Price:
                <input
                  type="number"
                  name="monthly_price"
                  value={pkg.monthly_price}
                  onChange={e =>
                    setPackages(prevPackages =>
                      prevPackages.map(p =>
                        p.subscription_package_id === pkg.subscription_package_id
                          ? { ...p, monthly_price: e.target.value }
                          : p
                      )
                    )
                  }
                  required
                />
              </label>
              <br />
              <label>
                Annual Price:
                <input
                  type="number"
                  name="annual_price"
                  value={pkg.annual_price}
                  onChange={e =>
                    setPackages(prevPackages =>
                      prevPackages.map(p =>
                        p.subscription_package_id === pkg.subscription_package_id
                          ? { ...p, annual_price: e.target.value }
                          : p
                      )
                    )
                  }
                />
              </label>
              <br />
              <label>
                Activation Fee:
                <input
                  type="number"
                  name="activation_fee"
                  value={pkg.activation_fee}
                  onChange={e =>
                    setPackages(prevPackages =>
                      prevPackages.map(p =>
                        p.subscription_package_id === pkg.subscription_package_id
                          ? { ...p, activation_fee: e.target.value }
                          : p
                      )
                    )
                  }
                />
              </label>
              <br />
              <label>
                Product Limit:
                <input
                  type="number"
                  name="product_limit"
                  value={pkg.product_limit}
                  onChange={e =>
                    setPackages(prevPackages =>
                      prevPackages.map(p =>
                        p.subscription_package_id === pkg.subscription_package_id
                          ? { ...p, product_limit: e.target.value }
                          : p
                      )
                    )
                  }
                  required
                />
              </label>
              <br />
              <button type="submit">Save Changes</button>
            </form>
            <hr />
          </div>
        ))
      )}
    </div>
    </>
  );
};