import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { AdminDashboard } from "./AdminDashboard";
import { ConfirmationModal } from "../Common/ConfirmationModal";
import { fetchApplications, deleteApplication, addNewShop } from "../Common/apiServiceAdmin";
import "../../styles/admin-manage-applications.css";

export const AdminManageApplications = () => {
  const [applications, setApplications] = useState([]);
  const [error, setError] = useState(null);
  const [applicationToDelete, setApplicationToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchApplications();
        setApplications(data);
      } catch (err) {
        console.error(err.message);
        setError("Error fetching applications. Please try again later.");
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleDeleteApplication = async (applicationId) => {
    setApplicationToDelete(applicationId);
    setShowConfirmationModal(true);
  };

  const deleteApplicationById = async (applicationId) => {
    try {
      const success = await deleteApplication(applicationId);
      if (success) {
        setApplications((prevApplications) =>
          prevApplications.filter((application) => application.application_id !== applicationId)
        );
        toast.success("Application deleted successfully!");
      } else {
        setError("Failed to delete application. Please try again later.");
      }
    } catch (err) {
      console.error(err.message);
      setError("Failed to delete application. Please try again later.");
    }
  };

  const handleDeleteConfirmation = async () => {
    await deleteApplicationById(applicationToDelete);
    setApplicationToDelete(null);
    setShowConfirmationModal(false);
  };

  const handleCancelDelete = () => {
    setApplicationToDelete(null);
    setShowConfirmationModal(false);
  };

  const handleStatusUpdate = async (applicationId) => {
    try {
      const approvedApplication = applications.find(
        (app) => app.application_id === applicationId
      );
      if (approvedApplication) {
        const newShopData = {
          shop_name: approvedApplication.shop_name,
          shop_description: approvedApplication.shop_description,
          shop_about: approvedApplication.shop_about,
          owner_name: approvedApplication.owner_name,
          owner_email: approvedApplication.owner_email,
          owner_phone: approvedApplication.owner_phone,
          shop_address: approvedApplication.shop_address,
          shop_subscription: approvedApplication.shop_subscription,
        };

        const response = await addNewShop(newShopData);
        if (response) {
          toast.success("Shop created successfully!");
          await deleteApplicationById(applicationId);
        } else {
          setError("Failed to create shop. Please try again later.");
        }
      } else {
        setError("Failed to find approved application details.");
      }
    } catch (err) {
      console.error(err.message);
      setError("Failed to create shop. Please try again later.");
    }
  };

  const getSubscriptionName = (subscriptionId) => {
    switch (subscriptionId) {
      case 1:
        return "Basic";
      case 2:
        return "Gold";
      case 3:
        return "Platinum";
      default:
        return "Unknown";
    }
  };

  return (
    <div className="admin-manage-applications">
      <Helmet>
        <title>Creatives SA - Manage Applications</title>
      </Helmet>
      <AdminDashboard />
      <h1>Manage Shop Applications</h1>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          {showConfirmationModal && (
            <ConfirmationModal
              message="Are you sure you want to delete this application?"
              onConfirm={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          {applications.length === 0 ? (
            <p>There are currently no new applications.</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Application ID</th>
                  <th>Shop Name</th>
                  <th>Owner Name</th>
                  <th>Owner Email</th>
                  <th>Owner Phone</th>
                  <th>Shop Address</th>
                  <th>Shop Subscriptions</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {applications.map((application) => (
                  <tr key={application.application_id}>
                    <td>{application.application_id}</td>
                    <td>{application.shop_name}</td>
                    <td>{application.owner_name}</td>
                    <td>{application.owner_email}</td>
                    <td>{application.owner_phone}</td>
                    <td>{application.shop_address}</td>
                    <td>{getSubscriptionName(application.shop_subscription)}</td>
                    <td>
                      <button
                        className="approve-btn"
                        onClick={() => handleStatusUpdate(application.application_id)}
                      >
                        Approve
                      </button>
                      <button
                        className="delete-btn"
                        onClick={() => handleDeleteApplication(application.application_id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};
