import axios from 'axios';
import { toast } from 'react-toastify';
import {API_BASE_URL} from "./Constants";

export const fetchShops = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/shops`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching shops:', error);
    throw error; // Propagate error to handle in components
  }
};

export const fetchCreativesHubUsers = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/creativeshub-users`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching shops:', error);
    throw error; // Propagate error to handle in components
  }
};

export const deleteShop = async (shopId) => {
  try {
    const shopResponse = await fetch(`${API_BASE_URL}/shops/${shopId}`);
    const shopData = await shopResponse.json();
    const imageKeys = [
      shopData.shop_image ? shopData.shop_image.split('/').pop() : null,
      shopData.shop_background ? shopData.shop_background.split('/').pop() : null
    ];    

    const deletePromises = [
      fetch(`${API_BASE_URL}/shops/${shopId}`, { method: "DELETE" }),
      ...imageKeys.map(key =>
        fetch(`${API_BASE_URL}/delete-image/${key}`, { method: "DELETE" })
      )
    ];

    await Promise.all(deletePromises);
    return true; 
  } catch (error) {
    console.error('Error deleting shop:', error);
    throw error; // Propagate error to handle in components
  }
};

export const deleteCreativesHubUser = async (CreativesHubUserId) => {
  try {
    await fetch(`${API_BASE_URL}/creativeshub-users/${CreativesHubUserId}`, { method: "DELETE" });
    return true; 
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error; // Propagate error to handle in components
  }
};


export const updateShopStatus = async (shopId, newStatus) => {
  try {
    const response = await fetch(`${API_BASE_URL}/shops/status/${shopId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shop_status: newStatus }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return true; // Successfully updated
  } catch (error) {
    console.error('Error updating shop status:', error);
    throw error; // Propagate error to handle in components
  }
};

export const updateCreativesHubUserStatus = async (CreativesHubUserId, newCreativesHubUserStatus) => {
  try {
    const response = await fetch(`${API_BASE_URL}/creativeshub-users/status/${CreativesHubUserId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ hub_user_status: newCreativesHubUserStatus }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return true; // Successfully updated
  } catch (error) {
    console.error('Error updating user status:', error);
    throw error; // Propagate error to handle in components
  }
};

export const updateShopRank = async (shopId, newRank) => {
  try {
    const response = await fetch(`${API_BASE_URL}/shops/${shopId}/rank`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ shop_rank: newRank }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return true; 
  } catch (error) {
    console.error('Error updating shop rank:', error);
    throw error; // Propagate error to handle in components
  }
};

export const updateCreativesHubUserRank = async (CreativesHubUserId, newRank) => {
  try {
    const response = await fetch(`${API_BASE_URL}/creativeshub-users/${CreativesHubUserId}/hub_user_rank`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ hub_user_rank: newRank }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return true; 
  } catch (error) {
    console.error('Error updating shop rank:', error);
    throw error; // Propagate error to handle in components
  }
};

export const fetchShopDetails = async (shopId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/shops/${shopId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching shop details:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const fetchCreativesHubUserDetails = async (CreativesHubUserId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/creativeshub-users/${CreativesHubUserId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching account details:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const updateShopDetails = async (shopId, updatedData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/shops/${shopId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return true; // Successfully updated
    } catch (error) {
      console.error('Error updating shop details:', error);
      throw error; // Propagate error to handle in components
    }
  };

    export const updateCreativesHubUserDetails = async (CreativesHubUserId, updatedData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/creativeshub-users/${CreativesHubUserId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return true; // Successfully updated
    } catch (error) {
      console.error('Error updating shop details:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const uploadShopImage = async (formData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/image-upload`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error uploading shop image:', error);
      throw error;
    }
  };

  export const fetchProductsData = async (shop_id) => {
    const response = await fetch(`${API_BASE_URL}/products/shop/${shop_id}/products`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  };

  export const fetchCreativesHubProductsData = async (hub_user_id) => {
    const response = await fetch(`${API_BASE_URL}/creativeshub-products/user/${hub_user_id}/products`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  };
  

  export const fetchSubscriptionPackages = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/packages`);
      if (!response.ok) {
        throw new Error('Failed to fetch subscription packages');
      }
      const data = await response.json();
  
      // Validate that data is an array
      if (!Array.isArray(data)) {
        throw new Error('Fetched data is not an array');
      }
  
      return data;
    } catch (error) {
      console.error('Error fetching subscription packages:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const fetchToolBarDetails = async (shopId) => {
    try {
      const productsResponse = await fetch(`${API_BASE_URL}/products/shop/${shopId}/products`);
      const shopResponse = await fetch(`${API_BASE_URL}/shops/${shopId}`);
  
      if (!productsResponse.ok) {
        throw new Error(`Failed to fetch products. Status: ${productsResponse.status}`);
      }
      if (!shopResponse.ok) {
        throw new Error(`Failed to fetch shop details. Status: ${shopResponse.status}`);
      }
  
      const productsData = await productsResponse.json();
      const itemsOnSale = Array.isArray(productsData) ? productsData.filter((product) => product.is_on_sale) : [];
  
      const shopData = await shopResponse.json();
  
      return {
        totalProducts: Array.isArray(productsData) ? productsData.length : 0,
        totalItemsOnSale: itemsOnSale.length,
        shopStatus: shopData.shop_status,
      };
    } catch (error) {
      console.error('Error fetching toolbar details:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const fetchCreativesHubToolBarDetails = async (hub_user_id) => {
    try {
      const productsResponse = await fetch(`${API_BASE_URL}/creativeshub-products/user/${hub_user_id}/products`);
      const userResponse = await fetch(`${API_BASE_URL}/creativeshub-users/${hub_user_id}`);
  
      // Check if the products endpoint returned 404 and handle it
      if (productsResponse.status === 404) {
        console.warn(`Products for user ${hub_user_id} not found. Skipping products details.`);
      } else if (!productsResponse.ok) {
        throw new Error(`Failed to fetch products. Status: ${productsResponse.status}`);
      }
  
      if (!userResponse.ok) {
        throw new Error(`Failed to fetch user details. Status: ${userResponse.status}`);
      }
  
      const productsData = productsResponse.status !== 404 ? await productsResponse.json() : [];
      const itemsOnSale = Array.isArray(productsData) ? productsData.filter((product) => product.is_on_sale) : [];
  
      const userData = await userResponse.json();
  
      return {
        totalProducts: Array.isArray(productsData) ? productsData.length : 0,
        totalItemsOnSale: itemsOnSale.length,
        userStatus: userData.hub_user_status,
        accountType: userData.hub_market,
      };
    } catch (error) {
      console.error('Error fetching toolbar details:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  

  export const fetchShopReviews = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/shops`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching shop reviews:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const fetchReviews = async (shopId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/reviews/${shopId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching reviews:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const fetchShopName = async (shopId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/shops/${shopId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data.shop_name;
    } catch (error) {
      console.error('Error fetching shop name:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const deleteReview = async (reviewId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/reviews/${reviewId}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error(`Failed to delete review ${reviewId}`);
      }
    } catch (error) {
      console.error(`Error deleting review ${reviewId}:`, error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const fetchProducts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/products`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const fetchCreativesHubProducts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/creativeshub-products`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/blog`);
      return response.data;
    } catch (error) {
      console.error('- Blogs currently unavailable, we apologize for any inconvience caused.', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const updateBlog = async (blogId, updatedBlogData) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/blog/${blogId}`, updatedBlogData);
      return response.data;
    } catch (error) {
      console.error('Error updating blog:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const uploadImage = async (formData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/image-upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data.objectUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error; // Propagate error to handle in components
    }
  };

export const addNewShop = async (newShopData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/shops`, newShopData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding new shop:', error);
    throw error; // Propagate error to handle in components
  }
};

export const addNewCreativesHubUser = async (newCreativesHubUserData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/creativeshub-users`, newCreativesHubUserData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding new shop:', error);
    throw error; 
  }
};

export const fetchLastProductID = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/products/last-product-id`);
      return response.data.last_id;
    } catch (error) {
      console.error('Error fetching last product ID:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const fetchCreativesHubLastProductID = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/creativeshub-products/last-product-id`);
      return response.data.last_id;
    } catch (error) {
      console.error('Error fetching last product ID:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const addProduct = async (productData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/products`, productData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error adding product:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const addCreativesHubProduct = async (productData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/creativeshub-products`, productData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error adding product:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const uploadProductImage = async (formData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/image-upload`, formData);
      return response.data.objectUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const updateProductDetails = async (productId, productData) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/products/${productId}`, productData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating product details:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const updateCreativesHubProductDetails = async (productId, productData) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/creativeshub-products/${productId}`, productData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating product details:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const deleteProduct = async (productId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/products/${productId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting product:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const deleteCreativesHubProduct = async (productId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/creativeshub-products/${productId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting product:', error);
      throw error; // Propagate error to handle in components
    }
  };

    export const deleteImage = async (key) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/delete-image/${key}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error; // Propagate error to handle in components
    }
  };
  
  export const fetchProductDetails = async (productId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/products/${productId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching product details:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const fetchCreativesHubProductDetails = async (productId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/creativeshub-products/${productId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching product details:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const fetchApplications = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/applications`);
      if (!response.ok) {
        throw new Error(`Failed to fetch applications. Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching applications:', error);
      throw error;
    }
  };
  
  export const deleteApplication = async (applicationId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/applications/${applicationId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`Failed to delete application. Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error deleting application:', error);
      throw error;
    }
  };

  export const fetchCreativesHubApplications = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/creativeshub-applications`);
      if (!response.ok) {
        throw new Error(`Failed to fetch applications. Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching applications:', error);
      throw error;
    }
  };
  
  export const deleteCreativesHubApplication = async (applicationId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/creativeshub-applications/${applicationId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`Failed to delete application. Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error deleting application:', error);
      throw error;
    }
  };
  
  export const fetchShopSubscriptionPackages = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/packages`);
      if (!response.ok) {
        throw new Error('Failed to fetch subscription packages');
      }
      const data = await response.json();
      if (!Array.isArray(data)) {
        throw new Error('Fetched data is not an array');
      }
      return data;
    } catch (error) {
      console.error('Error fetching packages:', error);
      toast.error('Failed to fetch subscription packages');
      throw error; // Propagate error to handle in components
    }
  };
  
  export const updateSubscriptionPackage = async (package_id, updatedPackage) => {
    try {
      const response = await fetch(`${API_BASE_URL}/packages/${package_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedPackage),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to update package details: ${JSON.stringify(errorData)}`);
      }
  
      const updatedData = await response.json();
      return updatedData.updatedPackage;
    } catch (error) {
      console.error('Error updating package:', error);
      toast.error('Error updating package details');
      throw error; 
    }
  };

  export const fetchSubscriptionPackagesById = async (shopId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/packages/shop/${shopId}/packages`);
      if (!response.ok) {
        throw new Error("Failed to fetch subscription packages");
      }
      const data = await response.json();
      
      // Validate that data is an array
      if (!Array.isArray(data)) {
        throw new Error("Fetched data is not an array");
      }
  
      return data;
    } catch (error) {
      console.error('Error fetching subscription packages:', error);
      throw error; // Propagate error to handle in components
    }
  };

  export const updateSubscriptionPackagesById = async (shopId, newPackageId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/packages/shop/${shopId}/packages`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ new_package_id: newPackageId }),
      });
      return response.ok;
    } catch (err) {
      console.error('Failed to update shop package:', err);
      return false;
    }
  };