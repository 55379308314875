import {API_BASE_URL} from "./Constants";

export const fetchShop = async (shop_id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/shops/${shop_id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Propagate error to handle in component
  }
};

export const fetchProducts = async (shop_id) => {
  const response = await fetch(`${API_BASE_URL}/products/shop/${shop_id}/products`);
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return response.json();
};

export const fetchReviews = async (shop_id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/reviews/${shop_id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching reviews:', error);
    throw error; // Propagate error to handle in component
  }
};

export const fetchReviewComments = async (shop_id, review_id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/reviews/${shop_id}/${review_id}/comments`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching review comments:', error);
    throw error; // Propagate error to handle in component
  }
};

export const addReview = async (shop_id, newReviewData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/reviews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ shop_id, ...newReviewData }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error adding review:', error);
    throw error; // Propagate error to handle in component
  }
};

export const addReviewComment = async (shop_id, reviewId, commentText) => {
  try {
    const response = await fetch(`${API_BASE_URL}/reviews/${shop_id}/${reviewId}/comments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ comment_text: commentText }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error adding review comment:', error);
    throw error; // Propagate error to handle in component
  }
};

export const fetchFeaturedProducts = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/products/featured`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    const shuffledProducts = [...data].sort(() => Math.random() - 0.5);
    return shuffledProducts;
  } catch (error) {
    console.error('Error fetching featured products:', error);
    throw error; // Propagate error to handle in component
  }
};

export const fetchShops = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/shops`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('- Unfortunately, ', error);
    throw error; // Propagate error to handle in component
  }
};

export const fetchBlog = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/blog`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    // Return first blog post or handle as needed
    return data.length > 0 ? data[0] : {};
  } catch (error) {
    console.error('Error fetching blog:', error);
    throw error; // Propagate error to handle in component
  }
};

export const fetchShopDetails = async (shop_id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/shops/${shop_id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching shop details:', error);
    throw error; // Propagate error to handle in component
  }
};

export const AddApplication = async (applicationData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/applications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(applicationData),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error adding application:', error);
    throw error; // Propagate error to handle in component
  }
};

export const AddCreativesHubApplication = async (applicationData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/creativeshub-applications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(applicationData),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error adding creatives hub application:', error);
    throw error; // Propagate error to handle in component
  }
};


export const fetchShopByOwnerEmail = async (email) => {
  try {
    const response = await fetch(`${API_BASE_URL}/shops/byOwnerEmail/${email}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching shop by owner email:', error);
    throw error;
  }
};

export const fetchCreativesHubCreatorsProducts = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/creativeshub-products/creators`);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch creators' products:", error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
};

