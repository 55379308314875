import React from 'react';

export const TermsAndConditions = ({ formData, setFormData }) => {
  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, termsAccepted: e.target.checked });
  };

  return (
    <div className="terms-and-conditions">
      <label>
        <input
          type="checkbox"
          name="termsAccepted"
          checked={formData.termsAccepted || false}
          onChange={handleCheckboxChange}
          required
        />
         I accept the <a href="https://www.creativessa.com/terms-of-service" target="_blank" rel="noopener noreferrer">terms and conditions</a>
      </label>
    </div>
  );
};
