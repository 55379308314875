import React from 'react';
import '../../styles/article.css';

export const Article = ({ blog, error }) => {

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (!blog || !blog.blog_heading || !blog.blog_description || !blog.blog_date || !blog.blog_img) {
    return <p>- No article available.</p>;
  }

  const { blog_heading, blog_description, blog_date, blog_img } = blog;

  return (
    <div className="article-section" id="blog">
      <div className="article">
        <div className="img-article-container" style={{ backgroundImage: `url(${blog_img})` }}>
        </div>
        <div className="article-content">
          <h1>{blog_heading}</h1>
          <p className="description">{blog_description}</p>
          <p className="author">{blog_date}</p>
        </div>
      </div>
    </div>
  );
};
