import * as Yup from 'yup';

const phoneRegex = /^(\+?\d{1,3}[-.\s]?|)?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

export const nameValidation = Yup.string().required('Name is required');
export const addressValidation = Yup.string().required('Address is required');
export const emailValidation = Yup.string().email('Invalid email address').required('Email is required');
export const phoneValidation = Yup.string().matches(phoneRegex, 'Invalid phone number').required('Phone number is required');
export const descriptionValidation = Yup.string().max(350, 'Description cannot exceed 350 characters').required('Description is required');
export const aboutValidation = Yup.string().max(350, 'About cannot exceed 350 characters').required('About is required');

