import React, { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import { Header } from '../Common/Header';
import { Featured } from '../Common/Featured';
import { ShopList } from '../Shop/ShopList';
import { CreativesHub } from '../CreativesHub/CreativesHub';
import { CTA } from '../Common/CTA';
import { Article } from '../Common/Article';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchFeaturedProducts, fetchShops, fetchBlog } from '../Common/apiService';
import headerImg from "../../assets/header.avif";

export const Home = () => {
    const queryClient = useQueryClient();

    const { data: featuredProducts, error: featuredError } = useQuery({
        queryKey: ['featuredProducts'],
        queryFn: fetchFeaturedProducts,
    });

    const { data: shops, error: shopsError } = useQuery({
        queryKey: ['shops'],
        queryFn: fetchShops,
    });

    const { data: blog, error: blogError } = useQuery({
        queryKey: ['blog'],
        queryFn: fetchBlog,
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            queryClient.invalidateQueries(['featuredProducts']);
        }, 5000);

        return () => clearTimeout(timer);
    }, [queryClient]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Creatives SA - Home Of Creativity</title>
            </Helmet>
            <Header backgroundImage={headerImg} />
            {featuredError && <p>- There are no featured Products to display, {featuredError.message}</p>}
            {featuredProducts && <Featured featuredProducts={featuredProducts} />}
                    
            {shopsError && <p>- Shops currently unavailable: {shopsError.message}</p>}
            {shops && shops.length > 0 ? (
                <ShopList shops={shops} />
            ) : (
                <p>- No shops to display at the moment, We apologize for any inconvenience caused.</p>
            )}
                    
            <CreativesHub />
            <CTA />
                    
            {blogError && <p>- Blogs currently unavailable, we apologize for any inconvenience caused. {blogError.message}</p>}
            {blog && <Article blog={blog} />}
        </>
    );
};

export default Home;
