import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { AdminDashboard } from "./AdminDashboard";
import { ConfirmationModal } from "../Common/ConfirmationModal";
import AdminEditRankModal from "./AdminEditRankModal";

import {
  fetchCreativesHubUsers,
  deleteCreativesHubUser,
  updateCreativesHubUserStatus,
  updateCreativesHubUserRank,
  fetchCreativesHubProductsData,
} from "../Common/apiServiceAdmin";
import "../../styles/admin-shop-list.css";

export const AdminCreativesHubUsers = () => {
  const [creatives, setCreatives] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showRankModal, setShowRankModal] = useState(false);
  const [selectedUserForRank, setSelectedUserForRank] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCreativesHubUsers();
        setCreatives(data);
      } catch (err) {
        console.error(err.message);
        setError("Error fetching creatives hub users. Please try again later.");
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleDeleteUser = async (userId) => {
    setUserToDelete(userId);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      // Fetch products by user ID to check if there are any associated products
      const products = await fetchCreativesHubProductsData(userToDelete);
      if (products.length > 0) {
        toast.error(
          "Cannot delete user. There are products associated with this user."
        );
        setShowConfirmationModal(false);
        return;
      }

      // Delete user from the database
      const success = await deleteCreativesHubUser(userToDelete);
      if (success) {
        setCreatives((prevCreatives) =>
          prevCreatives.filter((user) => user.hub_user_id !== userToDelete)
        );
        toast.success("User deleted successfully!");
      } else {
        setError("Failed to delete user. Please try again later.");
      }
    } catch (err) {
      console.error(err.message);
      setError("Failed to delete user. Please try again later.");
    } finally {
      setUserToDelete(null);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelDelete = () => {
    setUserToDelete(null);
    setShowConfirmationModal(false);
  };

  const handleStatusToggle = async (userId, newStatus) => {
    try {
      const success = await updateCreativesHubUserStatus(userId, newStatus);
      if (success) {
        toast.success("User status updated successfully!");
        setCreatives((prevCreatives) =>
          prevCreatives.map((user) =>
            user.hub_user_id === userId
              ? { ...user, user_status: newStatus }
              : user
          )
        );
      } else {
        setError("Failed to update user status. Please try again later.");
      }
    } catch (err) {
      console.error(err.message);
      setError("Failed to update user status. Please try again later.");
    }
  };

  const handleEditUserRank = (userId, currentRank) => {
    setSelectedUserForRank(userId);
    setShowRankModal(true);
  };

  const handleSaveRankChanges = async (newRank) => {
    try {
      const success = await updateCreativesHubUserRank(
        selectedUserForRank,
        newRank
      );
      if (success) {
        toast.success("User rank updated successfully!");
        setCreatives((prevCreatives) =>
          prevCreatives.map((user) =>
            user.hub_user_id === selectedUserForRank
              ? { ...user, hub_user_rank: newRank }
              : user
          )
        );
      } else {
        setError("Failed to update user rank. Please try again later.");
      }
    } catch (err) {
      console.error(err.message);
      setError("Failed to update user rank. Please try again later.");
    } finally {
      setShowRankModal(false);
      setSelectedUserForRank(null);
    }
  };

  const filteredCreatives = creatives.filter(
    (user) =>
      (user.hub_full_name &&
        user.hub_full_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.hub_email &&
        user.hub_email.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.hub_phone_number &&
        user.hub_phone_number
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (user.hub_address &&
        user.hub_address.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className="admin-shop-list">
      <Helmet>
        <title>Creatives SA - Manage Creatives Hub Users</title>
      </Helmet>
      <AdminDashboard />
      <h1>Manage Creatives Hub Users</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search user..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {searchQuery && (
          <button onClick={() => setSearchQuery("")}>Clear Result(s)</button>
        )}
      </div>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          {showConfirmationModal && (
            <ConfirmationModal
              message="Are you sure you want to delete this user?"
              onConfirm={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          {showRankModal && (
            <AdminEditRankModal
              initialRank={
                filteredCreatives.find(
                  (user) => user.hub_user_id === selectedUserForRank
                )?.hub_user_rank || ""
              }
              onSave={handleSaveRankChanges}
              onClose={() => setShowRankModal(false)}
              onCancel={() => setShowRankModal(false)}
            />
          )}
          <table>
            <thead>
              <tr>
                <th>User ID</th>
                <th>User Name</th>
                <th>User Phone</th>
                <th>User Email</th>
                <th>User Address</th>
                <th>User Rank</th>
                <th>Hub Market</th>
                <th>User Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCreatives.map((user) => (
                <tr key={user.hub_user_id}>
                  <td>{user.hub_user_id}</td>
                  <td>{user.hub_full_name}</td>
                  <td>{user.hub_phone_number}</td>
                  <td>{user.hub_email}</td>
                  <td>{user.hub_address}</td>
                  <td>
                    <button
                      className="shop-rank-btn"
                      onClick={() =>
                        handleEditUserRank(user.hub_user_id, user.hub_user_rank)
                      }
                    >
                      {user.hub_user_rank}
                    </button>
                  </td>
                  <td>
                    <button className="subscription-package-btn">
                      {user.hub_market}
                    </button>
                  </td>
                  <td>
                    <label className="toggle-label">
                      <input
                        type="checkbox"
                        checked={user.user_status === "active"}
                        onChange={(e) =>
                          handleStatusToggle(
                            user.hub_user_id,
                            e.target.checked ? "active" : "inactive"
                          )
                        }
                      />
                      <span className="back">
                        <span className="toggle"></span>
                        <span className="label on"></span>
                        <span className="label off"></span>
                      </span>
                    </label>
                  </td>
                  <td>
                    <Link
                      to={`/admin/creatives-hub-users/${user.hub_user_id}`}
                      className="button-link"
                    >
                      View Details
                    </Link>{" "}
                    <button onClick={() => handleDeleteUser(user.hub_user_id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
