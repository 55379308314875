import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../styles/admin-shop-subscription-package.css";
import { fetchSubscriptionPackagesById } from "../Common/apiServiceAdmin";

export const AdminShopSubscriptionPackage = () => {
  const { shop_id } = useParams();
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscriptionPackagesData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchSubscriptionPackagesById(shop_id);
        if (Array.isArray(data)) {
          setSubscriptionPackages(data);
        } else {
          setError("Fetched data is not an array");
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching subscription packages:", error);
        setError("Error fetching subscription packages. Please try again later.");
        setIsLoading(false);
      }
    };

    fetchSubscriptionPackagesData();
  }, [shop_id]);

  return (
    <div>
      <div className="admin-subscription-packages">
        <h1>YOUR SUBSCRIPTION PACKAGE</h1>
        {error && <p className="error-message">{error}</p>}
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            {Array.isArray(subscriptionPackages) && subscriptionPackages.length === 0 ? (
              <p>No subscription packages found for this shop.</p>
            ) : (
              <div>
                {Array.isArray(subscriptionPackages) && subscriptionPackages.map((pkg) => (
                  <div key={pkg.subscription_package_id} className="subscription-package">
                    <h2>{pkg.package_name}</h2>
                    <p>Description: {pkg.package_description}</p>
                    <p className="price-details">
                      Monthly Price: R{pkg.monthly_price}&nbsp;&nbsp;|
                      Annual Price: R{pkg.annual_price}&nbsp;&nbsp;|
                      Activation Fee: R{pkg.activation_fee}&nbsp;&nbsp;|
                      Product Limit: {pkg.product_limit}
                    </p>
                    <hr />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
