import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, Link, Navigate } from 'react-router-dom';
import '../../../styles/creativeshub-creatorproductlist.css';

const placeholderProducts = [
  {
    product_id: 1,
    product_name: 'Vintage Denim Jacket',
    product_description: 'A stylish vintage denim jacket perfect for any season.',
    price: 499.99,
    productimg_1: 'https://via.placeholder.com/150', 
    productimg_2: 'https://via.placeholder.com/150',
    productimg_3: 'https://via.placeholder.com/150',
  },
  {
    product_id: 2,
    product_name: 'Classic White T-Shirt',
    product_description: 'A classic white t-shirt made from 100% organic cotton.',
    price: 199.99,
    productimg_1: 'https://via.placeholder.com/150',
    productimg_2: 'https://via.placeholder.com/150',
    productimg_3: 'https://via.placeholder.com/150',
  },
  {
    product_id: 3,
    product_name: 'Floral Summer Dress',
    product_description: 'Light and breezy summer dress with floral patterns.',
    price: 299.99,
    productimg_1: 'https://via.placeholder.com/150',
    productimg_2: 'https://via.placeholder.com/150',
    productimg_3: 'https://via.placeholder.com/150',
  },
];

export const CreativesHubCreatorProductList = () => {
  const [products, setProducts] = useState(placeholderProducts);

  return (
    <div className="creativeshub-creator-products-list">
    <Helmet>
        <title>Creators Hub</title>
      </Helmet>
      <nav className="shop-navbar">
        <a href="#home" className="logo">Creators Hub</a>
        <ul className="shop-menu">
          <li><a href="#shop-footer">Contact</a></li>
          <li>
            <Link to="/cart" className="cart-icon">
              <i className="ri-shopping-cart-fill"></i>
              <span className="badge">0</span>
            </Link>
          </li>
        </ul>
      </nav>
      <h2>Creatives Hub - Clothing Products</h2>
      <div className="product-list">
        {products.map((product) => (
          <div key={product.product_id} className="product-card">
            <div className="product-image">
              <img src={product.productimg_1} alt={product.product_name} />
            </div>
            <div className="product-details">
              <h3>{product.product_name}</h3>
              <p>{product.product_description}</p>
              <p>Price: R{product.price}</p>
              {product.is_on_sale && <div className="sale-tag">ITEM IS ON SALE</div>}
            </div>
            <button className="add-to-cart-button">Add to Cart</button>
          </div>
        ))}
      </div>
    </div>
  );
};


