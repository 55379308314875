import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink as ScrollLink } from "react-router-hash-link";
import "../../styles/header.css";

export const Header = ({ backgroundImage }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const preloadImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = resolve;
        img.onerror = reject;
      });
    };

    preloadImage(backgroundImage).then(() => {
      setImageLoaded(true);
    });
  }, [backgroundImage]);

  const headerStyle = {
    backgroundImage: imageLoaded ? `url(${backgroundImage})` : "none",
  };

  return (
    <div style={headerStyle} className="header-section">
      <div className="header-text">
        <h1>The Home Of Creativity</h1>
        <p>Where creatives meet the fashion adventurer</p>
        <ScrollLink smooth to="/#shops">
          <button>
            Shops <i className="ri-shopping-bag-line"></i>
          </button>
        </ScrollLink>
      </div>
      <div className="header-buttons">
        <ScrollLink smooth to="/creatives-hub-home">
          <button>Thrift Market 101</button>
        </ScrollLink>
        <ScrollLink smooth to="/creatives-hub-home">
          <button>Designer Auction</button>
        </ScrollLink>
        <ScrollLink smooth to="/creatives-hub-home">
          <button>Request a Tailor-Made</button>
        </ScrollLink>
        <Link to="/sign-up">
          <button>Apply For a Shop</button>
        </Link>
      </div>
    </div>
  );
};
