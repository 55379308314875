import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { AdminShopToolBar } from "./AdminShopToolBar";
import { AdminShopSubscriptionPackage } from "./AdminShopSubscriptionPackage";
import { AdminEditShop } from "./AdminEditShop";
import { AdminEditProduct } from "./AdminEditProduct";
import { fetchShopDetails, updateShopDetails, uploadShopImage, fetchProductsData } from "../Common/apiServiceAdmin";
import "../../styles/admin.css";

export const AdminShopDetails = () => {
  const { shop_id } = useParams();
  const [productsData, setProductsData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editableFields, setEditableFields] = useState({
    shop_name: "",
    shop_description: "",
    shop_about: "",
    owner_name: "",
    owner_email: "",
    shop_address: "",
    owner_phone: "",
    shop_image: "",
    shop_background: "",
  });
  const [editableProductFields, setEditableProductFields] = useState([]);

  const fetchShopData = useCallback(async () => {
    try {
      const shopData = await fetchShopDetails(shop_id);
      // setShopDetails(shopData); // Remove this line
  
      setEditableFields({
        shop_name: shopData.shop_name,
        shop_description: shopData.shop_description,
        shop_about: shopData.shop_about,
        owner_name: shopData.owner_name,
        owner_email: shopData.owner_email,
        shop_address: shopData.shop_address,
        owner_phone: shopData.owner_phone,
        shop_image: shopData.shop_image,
        shop_background: shopData.shop_background,
      });
  
      const fetchedProductsData = await fetchProductsData(shop_id);
  
      if (!Array.isArray(fetchedProductsData)) {
        setProductsData([]);
        setEditableProductFields([]);
        setError("No products found for this shop.");
      } else {
        setProductsData(fetchedProductsData);
  
        const initialProductsFields = fetchedProductsData.map((product) => ({
          product_id: product.product_id,
          product_name: product.product_name,
          product_description: product.product_description,
          productimg_1: product.productimg_1,
          productimg_2: product.productimg_2,
          productimg_3: product.productimg_3,
          price: product.price,
        }));
  
        setEditableProductFields(initialProductsFields);
        setError(null); 
      }
    } catch (err) {
      console.error(err.message);
      setError("Error fetching shop details. Please try again later.");
    }
  }, [shop_id]);
  

  useEffect(() => {
    const fetchData = async () => {
      await fetchShopData();
      window.scrollTo(0, 0);
    };

    fetchData();
  }, [fetchShopData]);

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setEditableFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleImageChange = (event, field) => {
    const file = event.target.files[0];
    setSelectedImage({ ...selectedImage, [field]: file });
  };

  const handleUpdateShopDetails = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let shopImageURL = editableFields.shop_image;
      let shopBackgroundURL = editableFields.shop_background;

      if (selectedImage && selectedImage.shop_image) {
        const shopImageFilename = `${shop_id}-shop-image.png`;
        const shopImageFormData = new FormData();
        shopImageFormData.append(
          "image",
          selectedImage.shop_image,
          shopImageFilename
        );

        const shopImageResponse = await uploadShopImage(shopImageFormData);
        shopImageURL = shopImageResponse.objectUrl;
      }

      if (selectedImage && selectedImage.shop_background) {
        const shopBackgroundFilename = `${shop_id}-shop-background.png`;
        const shopBackgroundFormData = new FormData();
        shopBackgroundFormData.append(
          "image",
          selectedImage.shop_background,
          shopBackgroundFilename
        );

        const shopBackgroundResponse = await uploadShopImage(shopBackgroundFormData);
        shopBackgroundURL = shopBackgroundResponse.objectUrl;
      }

      const updatedShopData = {
        ...editableFields,
        shop_image: shopImageURL,
        shop_background: shopBackgroundURL,
      };

      const success = await updateShopDetails(shop_id, updatedShopData);
      if (success) {
        toast.success("Shop updated successfully!");
      } else {
        console.error("Failed to update shop details");
        toast.error("Failed to update shop details");
      }
    } catch (error) {
      console.error("Error updating shop details", error);
      toast.error("Error updating shop details");
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductInputChange = (e, productId) => {
    const { name, value } = e.target;
    const isOnSale = name === "is_on_sale" ? value === "t" : value;

    setEditableProductFields((prevFields) =>
      prevFields.map((field) =>
        field.product_id === productId ? { ...field, [name]: isOnSale } : field
      )
    );
  };

  return (
    <div>
      <Helmet>
        <title>Creatives SA - Manage Shop</title>
      </Helmet>
      <div className="admin-shop-details">
        <AdminShopToolBar shop_id={shop_id} />
        <h1>EDIT SHOP DETAILS</h1>
        {error ? (
          <p className="error-message">{error}</p>
        ) : (
          <div>
            <br />
            <AdminShopSubscriptionPackage shop_id={shop_id} />
            <br />
            <AdminEditShop
              editableFields={editableFields}
              handleInputChange={handleInputChange}
              handleUpdateShopDetails={handleUpdateShopDetails}
              handleImageChange={handleImageChange}
              isLoading={isLoading}
            />
            <AdminEditProduct
              editableProductFields={editableProductFields}
              handleProductInputChange={handleProductInputChange}
              products={productsData} // Pass productsData here
              shop_id={shop_id}
            />
          </div>
        )}
      </div>
    </div>
  );
};
