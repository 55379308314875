import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyADc2pugV0zwhsBfTVEvTgwz1aPRAWVxko",
  authDomain: "crerativessa.firebaseapp.com",
  projectId: "crerativessa",
  storageBucket: "crerativessa.appspot.com",
  messagingSenderId: "1092013444821",
  appId: "1:1092013444821:web:da539a1921074ca7181c5c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Auth

export const auth = getAuth(app);

export default app;
