import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useNavigate, Link } from "react-router-dom"; 
import { handleSignUp, handleLogin, redirectToShopPortal } from "./Auth";
import logoImg from "../../assets/logo.png";
import "../../styles/login.css";
import { Loader } from "../Common/Loader";

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleEmailAndPasswordSignUp = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      await handleSignUp(email, password);
      toast.success("Successfully signed up");
      await redirectToShopPortal(email, navigate);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailAndPasswordLogin = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      await handleLogin(email, password);
      toast.success("Successfully logged in");
      await redirectToShopPortal(email, navigate);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    if (!email || !password) {
      toast.error("Email and password are required");
      return false;
    }
    return true;
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Creatives SA - Login/Signup</title>
      </Helmet>
      <div className="login-container">
        {loading && <Loader message="Please wait..." />}
        <img src={logoImg} alt="Logo" className="logo" />
        <h1>Login | Signup</h1>
        <p className="login-description">
          Please ensure you have applied for a shop in our marketplace and your
          application has been approved. If you have already applied and
          received approval, you can proceed to login or sign up.
        </p>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          aria-label="Email"
        />
        <div className="password-input">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            aria-label="Password"
          />
          <i
            className={showPassword ? "ri-eye-off-line" : "ri-eye-line"}
            onClick={togglePasswordVisibility}
            aria-label="Toggle Password Visibility"
          ></i>
        </div>
        <button onClick={handleEmailAndPasswordSignUp} disabled={loading}>
          Sign Up
        </button>
        <button onClick={handleEmailAndPasswordLogin} disabled={loading}>
          Login
        </button>
        <div className="signup-link">
          <Link to="/sign-up">Haven't Applied Yet?</Link>
        </div>
      </div>
    </>
  );
};
