import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AdminDashboard } from "./AdminDashboard";
import { AdminHandleReviews } from "./AdminHandleReviews";
import { fetchShopDetails } from "../Common/apiServiceAdmin";
import "../../styles/admin.css";

export const AdminManageShopReviews = () => {
  const { shop_id } = useParams();
  const [error, setError] = useState(null);

  const [editableFields, setEditableFields] = useState({
    shop_name: "",
    owner_name: "",
    owner_email: "",
    owner_phone: "",
  });

  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const shopData = await fetchShopDetails(shop_id);
        setEditableFields({
          shop_name: shopData.shop_name,
          owner_name: shopData.owner_name,
          owner_email: shopData.owner_email,
          owner_phone: shopData.owner_phone,
        });
      } catch (err) {
        console.error(err.message);
        setError("Error fetching shop details. Please try again later.");
      }
    };

    fetchShopData();
  }, [shop_id]);

  return (
    <div className="admin-shop-details">
      <AdminDashboard />
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <div>
          <br />
          <div>
            <h2>MANAGE REVIEWS FOR: {editableFields.shop_name}</h2>
            <p><strong>Shop Name:</strong> {editableFields.shop_name}</p>
            <p><strong>Owner Name:</strong> {editableFields.owner_name}</p>
            <p><strong>Owner Email:</strong> {editableFields.owner_email}</p>
            <p><strong>Owner Phone:</strong> {editableFields.owner_phone}</p>
          </div>
          <AdminHandleReviews shop_id={shop_id} />
        </div>
      )}
    </div>
  );
};
