import React from 'react';
import '../../styles/creatives-hub-thrift.css';

export const CreativesHubThrift = () => {
  return (
    <div className="creatives-hub-thrift-card">
      <h2>Thrift SA</h2>
      <p>is coming soon</p>
    </div>
  );
};
