import React from "react";
import { Link } from "react-router-dom";
import { HashLink as ScrollLink } from "react-router-hash-link";
import "../../styles/footer.css";

export const Footer = () => {
  const handleHomeClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="footer-section">
      <h3 className="contact-title">Contact us</h3>
      <div className="grid">
        <div className="item a">
          Want to make your brand digital? Let's make it happen
        </div>
        <div className="item b">
          +27 73 392 8530
          <br />
          info@creativessa.com
          <br />
          Johannesburg
          <br />
          Gauteng, South Africa
        </div>
        <div className="item c">
          <Link to="/faq">FAQ's</Link>
          <br />
          <Link to="/terms-of-service">Terms of service</Link>
          <br />
          <Link to="/privacy-policy">Privacy policy</Link>
        </div>
        <div className="item d">
          <Link to="/" onClick={handleHomeClick}>
            Home
          </Link>
          <br />
          <ScrollLink to="/#shops">Shops</ScrollLink>
          <br />
          <ScrollLink to="/#creativeshub">Creatives Hub</ScrollLink>
        </div>
        <div className="item e">
          <Link to="/sign-up">Become a seller</Link>
          <br />
          <Link to="/login">Shop Owner</Link>
          <br />
          <Link to="/about">About us</Link>
        </div>
        <div className="item g" id="footer">
          <div className="social-icons">
            <a
              href="https://www.instagram.com/_creatives.sa/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="ri-instagram-line"></i>
            </a>
            <a
              href="https://www.facebook.com/people/Creatives-SA/100069662678553/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="ri-facebook-circle-line"></i>
            </a>
            <a
              href="https://www.youtube.com/@Creatives_SA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="ri-youtube-line"></i>
            </a>
            <a
              href="https://wa.me/27733928530"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="ri-whatsapp-line"></i>
            </a>
          </div>
          Explore Creative Brands
          <br />
          ©2024 Creatives SA
        </div>
      </div>
    </div>
  );
};
