import React, { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import "../../styles/privacy-policy.css";

export const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-policy-section">
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <div className="privacy-container">
        <h1>Privacy Policy</h1>
        <p>Last updated: 31/07/2024</p>
        <p>Welcome to Creatives SA! We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website creativessa.co.za. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
        
        <h2>1. Information We Collect</h2>
        <h3>1.1. Information You Provide</h3>
        <p>Personal Identification Information: Name, email address, phone number, and address. This information is provided by you during the checkout process or during account creation.</p>
        <p>Shop Information: Contact details such as shop emails and phone numbers, which are displayed on the front page of each shop.</p>
        <p>Creatives Hub Information: Users in the Creatives Hub may provide additional information related to their role as a Thrifter or Creator, including application information for account verification and listing products for sale or auction.</p>
        
        <h3>1.2. Information Automatically Collected</h3>
        <p>Usage Data: Information about your interactions with our site, including your IP address, browser type, and pages visited.</p>
        
        <h2>2. Use of Your Information</h2>
        <p>We use the information we collect in the following ways:</p>
        <ul>
          <li>To facilitate and process transactions, including those involving Creatives Hub listings and auctions.</li>
          <li>To communicate with you, including providing customer support.</li>
          <li>To improve our website and services, including enhancing the Creatives Hub experience.</li>
          <li>To comply with legal obligations.</li>
        </ul>
        
        <h2>3. Storage and Protection of Information</h2>
        <h3>3.1. Payment Information</h3>
        <p>Card Details: We do not store any card details. Our payment gateway service provider handles all transactions, and only EFT (Electronic Funds Transfer) is used.</p>
        <h3>3.2. User Information</h3>
        <p>Data Security: We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, loss, or destruction.</p>
        
        <h2>4. Disclosure of Information</h2>
        <p>We do not share your personal information with third parties except as necessary to complete a transaction or as required by law.</p>
        
        <h2>5. User Rights</h2>
        <p>Under POPIA, you have the right to:</p>
        <ul>
          <li>Access: Request access to your personal data.</li>
          <li>Correction: Request correction of inaccurate or incomplete data.</li>
          <li>Deletion: Request deletion of your personal data under certain circumstances.</li>
          <li>Objection: Object to the processing of your data for specific purposes.</li>
        </ul>
        
        <h2>6. Data Breach Procedures</h2>
        <p>In case of a data breach:</p>
        <ul>
          <li>Affected users will be notified within 72 hours.</li>
          <li>The breach will be investigated, and measures will be taken to prevent future breaches.</li>
        </ul>
        
        <h2>7. Policy for Shop Owners and Creatives Hub Users</h2>
        <p>Shop owners and Creatives Hub users on Creatives SA must adhere to the following guidelines:</p>
        <ul>
          <li>Data Handling: Shop owners and Creatives Hub users (Thrifters and Creators) will only have access to customer information provided during checkout or user interactions.</li>
          <li>Listing Limits: Creatives Hub users, both Thrifters and Creators, are limited to listing or auctioning a total of 5 products at any given time.</li>
          <li>Data Breach Consequences: If a shop or Creatives Hub user is found to have exposed customer information, their account will be suspended immediately. If the accusation is proven true, the account will be terminated and barred from the platform for at least six months.</li>
        </ul>
        
        <h2>8. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and where appropriate, notified to you by email.</p>
        
        <h2>9. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <p>Email: <a href="mailto:support@creativessa.co.za">support@creativessa.co.za</a></p>
        <p>Phone: <a href="tel:+27733928530">+27 73 392 8530</a></p>
      </div>
    </div>
  );
};
