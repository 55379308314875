import React, { useState, useEffect } from "react";
import { AdminDashboard } from "./AdminDashboard";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { Loader } from "../Common/Loader";
import { fetchBlogs, updateBlog, uploadImage } from "../Common/apiServiceAdmin";
import "../../styles/admin-blog.css";

export const AdminBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const [editableFields, setEditableFields] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchBlogData();
    window.scrollTo(0, 0);
  }, []);

  const fetchBlogData = async () => {
    setIsLoading(true);
    try {
      const blogsData = await fetchBlogs();
      setEditableFields(blogsData.map((blog) => ({ ...blog })));
      setBlogs(blogsData);
      setError(null);
    } catch (error) {
      console.error("Error fetching blogs:", error);
      setError("Failed to retrieve blogs. Please try again later.");
    }
    setIsLoading(false);
  };

  const handleInputChange = (e, index, field) => {
    const value = e.target.value;
    setEditableFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][field] = value;
      return updatedFields;
    });
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleSubmit = async (e, blog_id, index) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let objectUrl = "";

      if (selectedImage) {
        const formData = new FormData();
        formData.append("image", selectedImage);
        objectUrl = await uploadImage(formData);
      }

      const updatedBlogData = {
        ...editableFields[index],
        blog_img: objectUrl || editableFields[index].blog_img,
      };

      await updateBlog(blog_id, updatedBlogData);
      toast.success("Blog updated successfully!");
    } catch (error) {
      console.error("Error updating blog details:", error);
      toast.error("Error updating blog details");
    }

    setIsLoading(false);
  };

  return (
    <>
      <AdminDashboard />
      <div className="admin-blog">
        <Helmet>
          <title>Creatives SA - Edit Blog</title>
        </Helmet>

        <h1>Edit Blogs</h1>
        {error ? (
          <p className="error-message">{error}</p>
        ) : (
          <div>
            {blogs.map((blog, index) => (
              <form
                key={blog.blog_id}
                onSubmit={(e) => handleSubmit(e, blog.blog_id, index)}
              >
                <label>
                  Blog Heading:
                  <input
                    type="text"
                    name="blog_heading"
                    value={editableFields[index].blog_heading}
                    onChange={(e) =>
                      handleInputChange(e, index, "blog_heading")
                    }
                  />
                </label>
                <br />
                <label>
                  Blog Description:
                  <textarea
                    className="text-area"
                    type="text"
                    name="blog_description"
                    value={editableFields[index].blog_description}
                    onChange={(e) =>
                      handleInputChange(e, index, "blog_description")
                    }
                  />
                </label>
                <br />
                <label>
                  Blog Date:
                  <input
                    type="text"
                    name="blog_date"
                    value={editableFields[index].blog_date}
                    onChange={(e) => handleInputChange(e, index, "blog_date")}
                  />
                </label>
                <br />
                <label>
                  Blog Image:
                  <img
                    src={editableFields[index].blog_img}
                    alt="Article"
                    className="blog-img"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    name="blog_img"
                    onChange={handleImageChange}
                  />
                </label>
                <br />
                <button type="submit">Save Changes</button>
              </form>
            ))}
          </div>
        )}
        {isLoading && <Loader message="Updating Blog Details..." />}
      </div>
    </>
  );
};
