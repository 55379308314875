import React, { useState } from 'react';
import '../../styles/admin-edit-shop-rank.modal.css';

const AdminEditRankModal = ({ initialRank, onSave, onClose, onCancel }) => {
  const [rank, setRank] = useState(initialRank);

  const handleSave = () => {
    onSave(rank);
    onClose();
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10); 
    if (!isNaN(value) && value >= 1 && value <= 5) {
      setRank(value); 
    }
  };

  return (
    <div className="admin-edit-shop-rank-modal">
      <div className="modal-content">
      <h2>Edit Shop Ranking</h2>
        <p>Note: This will affect the positioning of the shop on the home page.</p>
        <label>
          Rank:
          <input
            type="number"
            value={rank}
            onChange={handleInputChange}
            min={1}
            max={5}
            step={1}
          />
        </label>
        <div className="modal-actions">
          <button className="save-btn" onClick={handleSave}>Save Changes</button>
          <button className="cancel-btn" onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default AdminEditRankModal;