import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { AdminDashboard } from "./AdminDashboard";
import { fetchShopReviews } from "../Common/apiServiceAdmin";
import "../../styles/admin-shop-list.css";

export const AdminShopReviews = () => {
  const [shops, setShops] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchShopsData = async () => {
      try {
        const shopsData = await fetchShopReviews();
        setShops(shopsData);
      } catch (err) {
        console.error(err.message);
        setError("Error fetching shops. Please try again later.");
      }
    };

    fetchShopsData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="admin-shop-list">
      <Helmet>
        <title>Manage Reviews</title>
      </Helmet>
      <AdminDashboard />
      <h1>Creatives SA - Manage Shop Reviews</h1>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Shop Name</th>
              <th>Shop Owner</th>
              <th>Shop Number</th>
              <th>Shop Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {shops.map((shop) => (
              <tr key={shop.shop_id}>
                <td>{shop.shop_name}</td>
                <td>{shop.owner_name}</td>
                <td>{shop.owner_phone}</td>
                <td>{shop.owner_email}</td>
                <td>
                  <Link
                    to={`/admin/reviews/${shop.shop_id}`}
                    className="button-link"
                  >
                    Manage Reviews
                  </Link>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
