import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase-config'; 

import { AdminShopList } from '../Admin/AdminShopList';
// import { AdminAddNewShop } from '../Admin/AdminAddNewShop';
import { AdminEditSubscriptionPackages } from '../Admin/AdminEditSubscriptionPackages';
import { AdminShopDetails } from '../Admin/AdminShopDetails';
import { AdminCreativesHubUserAccountDetails } from '../Admin/AdminCreativesHubUserAccountDetails';
import { AdminBlog } from '../Admin/AdminBlog';
import { AdminDashboard } from '../Admin/AdminDashboard';
import { AdminShopReviews } from '../Admin/AdminShopReviews';
import { AdminManageShopReviews } from '../Admin/AdminManageShopReviews';
import { AdminManageApplications } from '../Admin/AdminManageApplications';
import { AdminManageCreativesHubApplications } from '../Admin/AdminManageCreativesHubApplications';
import { AdminCreativesHubUsers } from '../Admin/AdminCreativesHubUsers';

export const ProtectedRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (isAuthenticated === null) {
    // Optionally, show a loading spinner while waiting for Firebase auth status
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Routes>
      <Route path="dashboard" element={<AdminDashboard />} />
      <Route path="shops" element={<AdminShopList />} />
      <Route path="creatives-hub-users" element={<AdminCreativesHubUsers />} />
      {/* <Route path="add-shops" element={<AdminAddNewShop />} /> */}
      <Route path="shops/:shop_id" element={<AdminShopDetails />} />
      <Route path="creatives-hub-users/:hub_user_id" element={<AdminCreativesHubUserAccountDetails />} />
      <Route path="blogs" element={<AdminBlog />} />
      <Route path="packages" element={<AdminEditSubscriptionPackages />} />
      <Route path="reviews" element={<AdminShopReviews />} />
      <Route path="reviews/:shop_id" element={<AdminManageShopReviews />} />
      <Route path="applications" element={<AdminManageApplications />} />
      <Route path="creatives-hub-applications" element={<AdminManageCreativesHubApplications />} />
    </Routes>
  );
};
