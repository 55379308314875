import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { StarRating } from "../Shop/StarRating";
import { ConfirmationModal } from "../Common/ConfirmationModal";
import { fetchReviews, fetchShopName, deleteReview } from "../Common/apiServiceAdmin"; 
import "../../styles/admin-handle-reviews.css";

export const AdminHandleReviews = ({ shop_id }) => {
  const [reviews, setReviews] = useState([]);
  const [shopName, setShopName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [reviewToDelete, setReviewToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reviewsData = await fetchReviews(shop_id);
        setReviews(reviewsData);

        const shopNameData = await fetchShopName(shop_id);
        setShopName(shopNameData);
      } catch (err) {
        console.error(err.message);
        setError("Error fetching data. Please try again later.");
      }
    };

    fetchData();
  }, [shop_id]);

  const filteredReviews = reviews.filter((review) =>
    review.review_user.toLowerCase().includes(searchQuery.toLowerCase()) ||
    review.review_email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    review.rating.toString().includes(searchQuery.toLowerCase())
  );

  const handleCancelDelete = () => {
    setReviewToDelete(null);
    setShowConfirmationModal(false);
  };

  const handleDeleteReview = async (review_id) => {
    try {
      await deleteReview(review_id);
      setReviews(reviews.filter((review) => review.review_id !== review_id));
      toast.success("Review deleted successfully!");
    } catch (error) {
      console.error(`Error deleting review ${review_id}`, error);
      toast.error("Error deleting review");
    } finally {
      setReviewToDelete(null);
      setShowConfirmationModal(false);
    }
  };

  return (
    <div className="shop-reviews">
      <div className="search-container">
        <input
          type="text"
          placeholder="Search review..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {searchQuery && (
          <button onClick={() => setSearchQuery("")}>Clear Result(s)</button>
        )}
      </div>

      {/* Confirmation modal for deleting reviews */}
      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to delete this review?"
          onConfirm={() => handleDeleteReview(reviewToDelete)}
          onCancel={handleCancelDelete}
        />
      )}

      <ul className="review-list">
        {error ? (
          <p className="error-message">{error}</p>
        ) : filteredReviews.length > 0 ? (
          filteredReviews.map((review) => (
            <li key={review.review_id} className="review-item">
              <p>User: {review.review_user}</p>
              <p>Email: {review.review_email}</p>
              <div className="user-info">
                <StarRating rating={review.rating} />
              </div>
              <p>Review: {review.review_text}</p>
              <button
                className="delete-review-button"
                onClick={() => {
                  setReviewToDelete(review.review_id);
                  setShowConfirmationModal(true);
                }}
              >
                Delete Review
              </button>
            </li>
          ))
        ) : (
          <p>No reviews available for {shopName}</p>
        )}
      </ul>
    </div>
  );
};
