import React, { useEffect, useState } from "react";
import { fetchShopSubscriptionPackages } from "./apiServiceAdmin";
import "../../styles/subscription-packages.css";

export const SubscriptionPackages = () => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchShopSubscriptionPackages();
        setPackages(data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchData();
  }, []);

  const getPackageCardClass = (pkgName) => {
    switch (pkgName) {
      case 'Platinum':
        return 'platinum-package';
      case 'Gold':
        return 'gold-package';
      case 'Basic':
        return 'basic-package'; 
      default:
        return 'default-package'; 
    }
  };

  return (
    <div className="subscription-packages">
      <h1>Subscriptions</h1>
      <div className="flexbox">
        {packages.map((pkg, index) => (
          <div className={`box ${getPackageCardClass(pkg.package_name)}`} key={index}>
            <h3 className={pkg.package_name.toLowerCase()}>{pkg.package_name}</h3>
            <div className="scrollbar" id={`content-scroll-${index}`}>
              <span>
                {pkg.package_name === 'Basic' && <i className="ri-star-line"></i>}
                {pkg.package_name === 'Gold' && <i className="ri-shield-star-line"></i>}
                {pkg.package_name === 'Platinum' && <i className="ri-shield-star-fill"></i>}
              </span>
              <p>{pkg.package_description}</p>
              <ul className="list-items">
                <li>Product Limit: {pkg.product_limit} Products</li>
                <li>Monthly Price: R{pkg.monthly_price}</li>
                <li>Annual Price: R{pkg.annual_price}</li>
                <li>Activation Fee: R{pkg.activation_fee}</li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


