import React, { useState } from "react";
import Modal from "react-modal";
import "../../../styles/creatives-hub-auction-bidding-modal.css";

export const CreativesHubAuctionBiddingModal = ({ isOpen, closeModal, product }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    bidAmount: ''
  });

  const [recentBids, setRecentBids] = useState([
    { name: 'John Doe', amount: 'R1300' },
    { name: 'Jane Smith', amount: 'R1250' },
    { name: 'Michael Brown', amount: 'R1200' },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleConfirmBid = () => {
    // Implement bid confirmation logic here
    console.log('Bid confirmed:', formData);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Bid Information"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        },
        content: {
          maxWidth: "500px",
          margin: "auto",
          padding: "20px",
          borderRadius: "10px",
          zIndex: 1001,
        },
      }}
    >
      <div className="bidding-modal-view">
        <h2>Place Your Bid for {product.title}</h2>
        <div className="recent-bids">
          <h3>Recent Bids</h3>
          <ul>
            {recentBids.map((bid, index) => (
              <li key={index}>{bid.name}: {bid.amount}</li>
            ))}
          </ul>
        </div>
        <div className="bid-form">
          <input
            type="text"
            name="fullName"
            placeholder="Full Name(s)"
            value={formData.fullName}
            onChange={handleInputChange}
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="bidAmount"
            placeholder="Bid Amount"
            value={formData.bidAmount}
            onChange={handleInputChange}
          />
          <button onClick={handleConfirmBid} className="confirm-bid-button">Confirm Bid</button>
        </div>
      </div>
    </Modal>
  );
};