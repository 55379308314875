import React, { useState } from "react";
import { Link } from "react-router-dom";
import CTAImg from "../../assets/CTA-img.webp";
import "../../styles/cta.css";

export const CTA = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleGetStarted = () => {
    if (!email) {
      setErrorMessage("Please enter your email address");
    }
  };

  return (
    <div className="CTA-section" id="home">
      <div className="CTA-content">
        <h1>Do you own a brand? Let us show your brand to the world.</h1>
        <p>
          Together, let's celebrate the spirit of creativity and uplift our
          youth. Discover the hidden gems, embrace the innovation, and become a
          part of the Creative South Africa movement.
        </p>
        <div className="CTA-content__input">
          <input
            type="email"
            placeholder="Your Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Link
            to={email ? `/sign-up?email=${encodeURIComponent(email)}` : "#"}
            onClick={handleGetStarted}
            className="get-started-link"
          >
            Get Started
          </Link>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="CTA-content"></div>
      </div>
      <div className="CTA-image">
        <img src={CTAImg} alt="CTAImage" />
      </div>
    </div>
  );
};
