import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const DownloadCheckoutDetailsButton = ({ targetId, fileName }) => {
  const handleDownloadPDF = async () => {
    const input = document.getElementById(targetId);
    if (!input) return;

    const canvas = await html2canvas(input, { useCORS: true });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 210;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    pdf.save(fileName);
  };

  return (
    <button onClick={handleDownloadPDF} className="download-btn">
      <i className="ri-download-line"></i>
      Download Checkout Details
    </button>
  );
};
