import React from "react";
import CreativesHubHeaderImg from "../../assets/creatives-hub-header.webp";
import "../../styles/creatives-hub-header.css";

export const CreativesHubHeader = () => {


  return (
    <div className="creativesHubHeader-section" id="home">
      <div className="creativesHubHeader-content">
        <h1>Welcome to the future!</h1>
        <p>
        You have just stepped into a dynamic hub where innovation meets artistry. Here, your unique vision transforms into reality, surrounded by a community that celebrates and supports your creative journey. Explore, connect, and inspire—your future begins now
        </p>
        <div className="creativesHubHeader-content"></div>
      </div>
      <div className="creativesHubHeader-image">
        <img src={CreativesHubHeaderImg} alt="creativesHubHeaderImage" />
      </div>
    </div>
  );
};
