import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import blockedImg from "../../assets/blocked-img.avif";
import "../../styles/blocked-shop-alert.css";

const BlockedShopAlert = () => {
  return (
    <>
      <Helmet>
        <title>Creatives SA - Home</title>
      </Helmet>
      <div className="blocked-shop-alert">
        <h2>Shop Temporarily Unavailable</h2>
        <p>
          Thank you for being a part of Creatives SA. Currently, this shop is
          inactive and cannot be accessed. This could be due to pending payments
          or a request from the shop owner.
        </p>
        <p>
          If you are the shop owner, please check your email for instructions on
          how to reactivate your shop.
        </p>
        <img src={blockedImg} alt="Shop Inactive" className="inactive-image" />
        <Link to="/" className="home-link">
          Return to Home
        </Link>
      </div>
    </>
  );
};

export default BlockedShopAlert;
