import React, { useState, useEffect, useRef } from "react";
import  ShopCard  from "./ShopCard";
import "../../styles/shoplist.css";

export const ShopList = ({ shops, error }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredShops, setFilteredShops] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const shopsPerPage = 32;

  const shopListRef = useRef(null); 

  useEffect(() => {
    setFilteredShops(shops);
  }, [shops]);

  const clearSearch = () => {
    setSearchQuery("");
    setFilteredShops(shops);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchFilteredShops = async () => {
      try {
        if (searchQuery.trim() === "") {
          const activeShops = shops.filter((shop) => shop.shop_status === "active");
          setFilteredShops(activeShops);
        } else {
          const filtered = shops.filter(
            (shop) =>
              shop.shop_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
              shop.shop_status === "active"
          );
          setFilteredShops(filtered);
        }
        setCurrentPage(1); 
      } catch (error) {
        console.error("Error fetching filtered shops:", error);
      }
    };

    fetchFilteredShops();
  }, [searchQuery, shops]);

  const indexOfLastShop = currentPage * shopsPerPage;
  const indexOfFirstShop = indexOfLastShop - shopsPerPage;
  const currentShops = filteredShops.slice(indexOfFirstShop, indexOfLastShop);

  const totalPages = Math.ceil(filteredShops.length / shopsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      shopListRef.current.scrollIntoView({ behavior: "smooth" }); 
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      shopListRef.current.scrollIntoView({ behavior: "smooth" }); 
    }
  };

  if (error) {
    console.error("API error:", error);
    return <p>There are no shops to display, We apologize for any inconvience caused.</p>;
  }

  if (!shops.length) {
    return <p>No shops available.</p>;
  }

  return (
    <div className="shop-list-section" id="shops" ref={shopListRef}>
      <h3 className="small-title">
        <i className="ri-shopping-cart-2-line"></i> - BROWSE ONLINE SHOPS
      </h3>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search shop..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {searchQuery && (
          <button className="clear-button" onClick={clearSearch}>
            Clear Result(s)
          </button>
        )}
      </div>
      <div className="shop-list-container">
        <div className="row">
          {currentShops.map((shop) => (
            <ShopCard key={shop.shop_id} shop={shop} />
          ))}
        </div>
      </div>
      <div className="pagination-container">
        <button
          className="pagination-button"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="pagination-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="pagination-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};
