import React, { useState, useRef, useEffect } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { HashLink as ScrollLink } from "react-router-hash-link";
import logoImg from "../../assets/logo.png";
import "../../styles/navbar.css";

export const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setToggleMenu(false);
    }
  };

  useEffect(() => {
    if (toggleMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleMenu]);

  return (
    <div className="navbar">
      <div className="navbar-links">
        <Link to="/" className="navbar-links_logo" onClick={() => setToggleMenu(false)}>
          <img src={logoImg} alt="Logo" />
        </Link>
        <div className="navbar-links_container">
          <h3>
            <Link to="/" onClick={() => setToggleMenu(false)}>Home</Link>
          </h3>
          <h3>
            <ScrollLink smooth to="/#shops" duration={1000} offset={-50} onClick={() => setToggleMenu(false)}>Shops</ScrollLink>
          </h3>
          <h3>
            <ScrollLink smooth to="/#creativeshub" duration={1000} offset={-50} onClick={() => setToggleMenu(false)}>Creatives Hub</ScrollLink>
          </h3>
          <h3>
            <ScrollLink smooth to="/#blog" duration={1000} offset={-50} onClick={() => setToggleMenu(false)}>Blog</ScrollLink>
          </h3>
          <h3>
            <ScrollLink smooth to="/#footer" duration={1000} offset={-50} onClick={() => setToggleMenu(false)}>Contact</ScrollLink>
          </h3>
        </div>
      </div>
      <div className="navbar-sign">
        <Link to="/sign-up" className="button" onClick={() => setToggleMenu(false)}>Sign up</Link>
      </div>
      <div className="navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#black" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#black" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center" ref={menuRef}>
            <div className="navbar-menu_container-links">
              <p>
                <Link to="/" onClick={() => setToggleMenu(false)}>Home</Link>
              </p>
              <p>
                <ScrollLink smooth to="/#shops" duration={1000} offset={-50} onClick={() => setToggleMenu(false)}>Shops</ScrollLink>
              </p>
              <p>
                <ScrollLink smooth to="/#creativeshub" duration={1000} offset={-50} onClick={() => setToggleMenu(false)}>Creatives Hub</ScrollLink>
              </p>
              <p>
                <ScrollLink smooth to="/#blog" duration={1000} offset={-50} onClick={() => setToggleMenu(false)}>Blog</ScrollLink>
              </p>
              <p>
                <ScrollLink smooth to="/#footer" duration={1000} offset={-50} onClick={() => setToggleMenu(false)}>Contact</ScrollLink>
              </p>
            </div>
            <div className="navbar-menu_container-links-sign">
              <Link to="/sign-up" className="button" onClick={() => setToggleMenu(false)}>Sign up</Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
