import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import SignUpFormOptions from "./SignUpFormOptions";
import ShopSignUpForm from "./ShopSignUpForm";
import CreativesHubSignUpForm from "./CreativesHubSignUpForm";
import "../../styles/signup-form.css";

export const SignUpForm = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleBackToOptions = () => {
    setSelectedOption(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Apply Now</title>
      </Helmet>
      <div className="signup-page">
        {!selectedOption ? (
          <SignUpFormOptions onSelectOption={handleOptionSelect} />
        ) : selectedOption === "shop" ? (
          <ShopSignUpForm handleBackToOptions={handleBackToOptions} />
        ) : (
          <CreativesHubSignUpForm handleBackToOptions={handleBackToOptions} />
        )}
      </div>
    </>
  );
};

export default SignUpForm;
