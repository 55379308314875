import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase-config";
import { fetchShopByOwnerEmail } from "../Common/apiService";

const handleSignUp = async (email, password) => {
  try {
    // Check if the email already exists in the database
    const data = await fetchShopByOwnerEmail(email);

    if (!data.shop_id) {
      throw new Error("User not associated with any active shop");
    }

    // Create a new user
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

const handleLogin = async (email, password) => {
  try {
    // Sign in with email and password
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    throw error;
  }
};

const redirectToShopPortal = async (email, navigate) => {
  try {
    // Check if the authenticated user's UID is the global administrator UID
    const currentUser = auth.currentUser;
    const globalAdminUID = "rxZuEmZzXCMZYtPxP3xwFX6LR8v1";
    if (currentUser && currentUser.uid === globalAdminUID) {
      navigate(`/admin/shops`);
    } else {
      // If not a global administrator, continue with the existing logic to redirect to the respective shop admin portal
      const data = await fetchShopByOwnerEmail(email);

      if (data.shop_id) {
        navigate(`/admin/shops/${data.shop_id}`); 
      } else {
        throw new Error("Shop ID not found for the user");
      }
    }
  } catch (error) {
    throw error;
  }
};

export { handleSignUp, handleLogin, redirectToShopPortal };
