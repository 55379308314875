import React, { useState } from 'react';
import { Helmet } from "react-helmet-async";
import { CheckoutForm } from './CheckoutForm';
import { DeliveryMethod } from './DeliveryMethod';
import { DownloadCheckoutDetailsButton } from './DownloadCheckoutDetailsButton';
import { TermsAndConditions } from './TermsAndConditions';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/checkout.css';

export const Checkout = () => {
  const { state } = useLocation();
  const navigate = useNavigate(); 
  const totalPrice = state?.totalPrice || 0;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    deliveryMethod: '',
    termsAccepted: false,
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (formData.termsAccepted) {
      console.log('Form Data Submitted:', formData);
      // Proceed with checkout process...
    } else {
      alert('Please accept the terms and conditions to proceed.');
    }
  };

  const handleBackToCart = () => {
    navigate('/cart');
  };

  return (
    <div className="checkout-page" id="checkout-content">
    <Helmet>
        <title>Checkout</title>
      </Helmet>
      <div className="checkout-header">
        <h2>Checkout</h2>
        <DownloadCheckoutDetailsButton targetId="checkout-content" fileName="checkout-details.pdf" />
      </div>
      <p>Total Price: R{totalPrice}</p>
      <form onSubmit={handleFormSubmit}>
        <CheckoutForm formData={formData} setFormData={setFormData} />
        <DeliveryMethod formData={formData} setFormData={setFormData} />
        <TermsAndConditions formData={formData} setFormData={setFormData} />
        <button onClick={handleBackToCart} className="back-to-cart-btn">Back to Cart</button>
        <button type="submit" className="submit-btn">Proceed to Payment</button>
      </form>
    </div>
  );
};

export default Checkout;
