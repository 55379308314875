import React from 'react';

export const CheckoutForm = ({ formData, setFormData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="checkout-form">
      <h3>Your Details</h3>
      <label>
        Name and Surname *
        <input 
          type="text" 
          name="name" 
          value={formData.name} 
          onChange={handleInputChange} 
          required 
        />
      </label>
      <label>
        Email Address *
        <input 
          type="email" 
          name="email" 
          value={formData.email} 
          onChange={handleInputChange} 
          required 
        />
      </label>
      <label>
        Mobile Number *
        <input 
          type="tel" 
          name="mobile" 
          value={formData.mobile} 
          onChange={handleInputChange} 
          required 
        />
      </label>
    </div>
  );
};
