import React from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { AddApplication } from "../../components/Common/apiService";
import { SubscriptionPackages } from "./SubscriptionPackages";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  nameValidation,
  addressValidation,
  emailValidation,
  phoneValidation,
  descriptionValidation,
  aboutValidation,
} from "../Common/formValidations";
import "../../styles/signup-form.css";

const ShopSignUpForm = ({ handleBackToOptions, initialEmail }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      shop_name: "",
      shop_about: "",
      shop_description: "",
      owner_name: "",
      owner_email: initialEmail || "",
      owner_phone: "",
      shop_address: "",
      shop_subscription: "",
    },
    validationSchema: Yup.object({
      shop_name: nameValidation,
      shop_about: aboutValidation,
      shop_description: descriptionValidation,
      owner_name: nameValidation,
      owner_email: emailValidation,
      owner_phone: phoneValidation,
      shop_address: addressValidation,
      shop_subscription: Yup.string().required(
        "Subscription package is required"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await AddApplication(values);
        toast.success("Application submitted successfully");
        resetForm();
        navigate("/application-complete");
      } catch (error) {
        console.error("Error submitting application:", error);
        toast.error("Failed to submit application");
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Apply For A Shop</title>
      </Helmet>
      <SubscriptionPackages />
      <div className="signup-form">
        <h2>Apply for a Shop</h2>

        <form onSubmit={formik.handleSubmit}>
          <label>Shop Name:</label>
          <input
            type="text"
            name="shop_name"
            value={formik.values.shop_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.shop_name && formik.errors.shop_name ? (
            <div className="error-message">{formik.errors.shop_name}</div>
          ) : null}

          <label>Shop About:</label>
          <textarea
            name="shop_about"
            value={formik.values.shop_about}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></textarea>
          {formik.touched.shop_about && formik.errors.shop_about ? (
            <div className="error-message">{formik.errors.shop_about}</div>
          ) : null}

          <label>Shop Description:</label>
          <textarea
            name="shop_description"
            value={formik.values.shop_description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></textarea>
          {formik.touched.shop_description && formik.errors.shop_description ? (
            <div className="error-message">
              {formik.errors.shop_description}
            </div>
          ) : null}

          <label>Owner Name:</label>
          <input
            type="text"
            name="owner_name"
            value={formik.values.owner_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.owner_name && formik.errors.owner_name ? (
            <div className="error-message">{formik.errors.owner_name}</div>
          ) : null}

          <label>Owner Email:</label>
          <input
            type="email"
            name="owner_email"
            value={formik.values.owner_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.owner_email && formik.errors.owner_email ? (
            <div className="error-message">{formik.errors.owner_email}</div>
          ) : null}

          <label>Owner Phone:</label>
          <input
            type="tel"
            name="owner_phone"
            value={formik.values.owner_phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.owner_phone && formik.errors.owner_phone ? (
            <div className="error-message">{formik.errors.owner_phone}</div>
          ) : null}

          <label>Shop Address:</label>
          <input
            type="text"
            name="shop_address"
            value={formik.values.shop_address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.shop_address && formik.errors.shop_address ? (
            <div className="error-message">{formik.errors.shop_address}</div>
          ) : null}

          <label>Subscription Package:</label>
          <select
            name="shop_subscription"
            value={formik.values.shop_subscription}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">Select a package</option>
            <option value="1">Basic</option>
            <option value="2">Gold</option>
            <option value="3">Platinum</option>
          </select>
          {formik.touched.shop_subscription &&
          formik.errors.shop_subscription ? (
            <div className="error-message">
              {formik.errors.shop_subscription}
            </div>
          ) : null}

          <button type="submit">Submit</button>
        </form>
        <button type="button" onClick={handleBackToOptions}>
          Back
        </button>
      </div>
    </>
  );
};

export default ShopSignUpForm;
