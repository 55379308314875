import React, { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import "../../styles/creatives-hub-home.css";
import { CreativesHubHeader } from "./CreativesHubHeader";
import { CreativesHubAuction } from "./Auction/CreativesHubAuction";
import { CreativesHubTailorMade } from "./CreativesHubTailorMade";
import { CreativesHubThrift } from "./CreativesHubThrift";

export const CreativesHubHome = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="creatives-hub-home-section">
      <Helmet>
        <title>Creatives SA - Hub</title>
      </Helmet>
      <CreativesHubHeader/>
      <CreativesHubAuction/>
      <CreativesHubTailorMade/>
      <CreativesHubThrift/>
    </div>
  );
};
