import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/shop-application-complete.css';

export const ShopApplicationComplete = () => {
  return (
    <>
    <div className="shop-application-complete">
      <div className="complete-content">
        <i className="ri-mail-check-line icon"></i>
        <h2>Application Received!</h2>
        <p>
          Thank you for applying to join Creatives SA! We have received your application and our team is currently reviewing it. Please note that the review process can take up to 24 hours on weekdays, and up to 48 hours on weekends.
        </p>
        <p>
          You will receive an email notification regarding the status of your application. We appreciate your patience and look forward to having you as a part of our vibrant community of creators.
        </p>
        <Link to="/" className="home-button">
          Return to Home Page
        </Link>
      </div>
    </div>
    </>
  );
};
